import { isMobile } from "helpers/const";
import _ from "lodash";
import React, { useContext, useMemo, useRef } from "react";
import Checkbox from "../../../../components/formComponent/Checkbox";
import DatePickerField from "../../../../components/formComponent/DatePickerField";
import DropDownField from "../../../../components/formComponent/DropDownField";
import MultiDropDownField from "../../../../components/formComponent/MultiDropDownField";
import {
  handleSelectDeselectAll,
  removeEmptyValues,
} from "../../../../helpers/Functions";
import { WalletAndBankDetailsContext } from "../core/WalletAndBankDetailsProvider";

const CommonFilter = ({
  handleOnChange,
  options,
  value,
  loading,
  handleOnApplyFilters,
  filterVisibile,
}: any) => {
  const layout = useContext(WalletAndBankDetailsContext);
  const sellerWalletsFilter = layout.sellerWalletsFilter;
  const bankAccountFilter = layout.bankAccountFilter;
  const withdrawRequestFilter = layout.withdrawRequestFilter;

  const teamsRef: any = useRef(null);
  const walletStatusRef: any = useRef(null);

  const windrowRequest: any = useRef(null);

  const {
    sellerWalletsFilterCopy,
    bankAccountFilterCopy,
    withdrawRequestFilterCopy,
  } = layout.commonFilter;

  // HEADER FILTER
  const getFilterItems = () =>
    layout.filterItems?.filter((obj: any) => obj?.checked === true);

  // HANDLE SELECT-DESELECT FOT TEAMS
  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any,
    searchName: string = "name"
  ) => {
    let isNameAvailable = options?.some((item: any) => item?.[`${searchName}`]);

    // MAP WHICH IS STATE
    const filterSetterMap: any = {
      "Seller wallets": layout.setSellerWalletsFilter,
      "Bank accounts": layout.setBankAccountFilter,
      "Withdraw requests": layout.setWithdrawRequestFilter,
    };

    const filterSetter = filterSetterMap[layout.isTabActive];

    handleSelectDeselectAll(
      data,
      fieldName,
      options,
      visibleOptions,
      filterSetter,
      false,
      searchName
    );

    if (teamsRef && teamsRef?.current) {
      teamsRef?.current?.blur();
    }
    if (walletStatusRef && walletStatusRef?.current) {
      walletStatusRef?.current?.blur();
    }
    if (windrowRequest && windrowRequest?.current) {
      windrowRequest?.current?.blur();
    }

    // IF ALL SELECTED
    // if (data?.target?.id === "select-all") {
    //   filterSetter((currentValue: any) => {
    //     let final = {
    //       ...currentValue,
    //       [fieldName]: isNameAvailable
    //         ? options?.filter((option: any) => option?.[`${searchName}`])
    //         : extractOptions(options),
    //       page: 1,
    //     };
    //     delete final?.id;
    //     return final;
    //   });
    // } else if (data?.target?.id === "deselect-all") {
    //   filterSetter((currentValue: any) => {
    //     let final = {
    //       ...currentValue,
    //       [fieldName]: [],
    //       page: 1,
    //     };
    //     delete final?.id;
    //     return final;
    //   });
    // }
  };

  const applyButtonDisabled = useMemo(() => {
    const activeFilter =
      layout.isTabActive === "Seller wallets"
        ? sellerWalletsFilter
        : layout.isTabActive === "Bank accounts"
        ? bankAccountFilter
        : withdrawRequestFilter;
    const copyactiveFilter =
      layout.isTabActive === "Seller wallets"
        ? sellerWalletsFilterCopy
        : layout.isTabActive === "Bank accounts"
        ? bankAccountFilterCopy
        : withdrawRequestFilterCopy;

    const obj1 = _.cloneDeep(activeFilter);
    const obj2 = _.cloneDeep(copyactiveFilter);
    delete obj1.page;
    delete obj1.per_page;
    delete obj2.page;
    delete obj2.per_page;

    const removeEmptyvalue1 = removeEmptyValues(obj1);
    const removeEmptyvalue2 = removeEmptyValues(obj2);

    return _.isEqual(removeEmptyvalue1, removeEmptyvalue2);
  }, [
    sellerWalletsFilter,
    bankAccountFilter,
    withdrawRequestFilter,
    sellerWalletsFilterCopy,
    bankAccountFilterCopy,
    withdrawRequestFilterCopy,
    layout.isTabActive,
  ]);

  return (
    <React.Fragment>
      {getFilterItems()?.map((columnItem: any, index: number) => {
        switch (columnItem.id) {
          case 1:
            return (
              <React.Fragment key={index}>
                {/* Team */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <MultiDropDownField
                      classNamePrefix={"form_multiDropDown"}
                      placeholder="Teams"
                      options={options?.options_teams}
                      name="teams"
                      value={value?.teams}
                      selectedRef={teamsRef}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      handleOnSelection={(data: any, visibleOptions: any) => {
                        handleOnSelection(
                          data,
                          "teams",
                          options?.options_teams,
                          visibleOptions
                        );
                      }}
                    />
                  </div>
                </div>
              </React.Fragment>
            );

          case 14:
            return (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
              >
                <div className={` rounded  ${loading && "shimmer-effect"} `}>
                  <MultiDropDownField
                    classNamePrefix={"form_multiDropDown"}
                    placeholder="Wallet status"
                    options={options?.options_walletstatus}
                    name="statuses"
                    value={value?.statuses}
                    handleOnChange={(data: any) => {
                      handleOnChange(data);
                    }}
                    selectedRef={walletStatusRef}
                    handleOnSelection={(data: any, visibleOptions: any) =>
                      handleOnSelection(
                        data,
                        "statuses",
                        options?.options_walletstatus,
                        visibleOptions
                      )
                    }
                  />
                </div>
              </div>
            );
          case 15:
            return (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
              >
                <div className={` rounded  ${loading && "shimmer-effect"} `}>
                  <MultiDropDownField
                    classNamePrefix={"form_multiDropDown"}
                    placeholder="Withdraw request status"
                    options={options?.options_withdraw_request_statuses}
                    name="statuses"
                    value={value?.statuses}
                    handleOnChange={(data: any) => {
                      handleOnChange(data);
                    }}
                    selectedRef={windrowRequest}
                    handleOnSelection={(data: any, visibleOptions: any) =>
                      handleOnSelection(
                        data,
                        "statuses",
                        options?.options_withdraw_request_statuses,
                        visibleOptions
                      )
                    }
                  />
                </div>
              </div>
            );
          case 16:
            return (
              <React.Fragment key={index}>
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <DatePickerField
                      name="requestedDate"
                      placeholder="Requested date"
                      disabledKeyboardNavigation={false}
                      isDateRange={true}
                      value={value?.requestedDate}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      format="dd/MM/yy"
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 19:
            return (
              <React.Fragment key={index}>
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <Checkbox
                      label="Change requested"
                      id="txpay_change_request"
                      name="txpay_change_request"
                      labelclass="text-gray-500"
                      checked={value?.txpay_change_request}
                      tooltip={false}
                      handleOnChange={(data: any, e: any) => {
                        handleOnChange(data);
                      }}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 20:
            return (
              <React.Fragment key={index}>
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] min-w-[16.25rem] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <DropDownField
                      options={[
                        { id: 1, name: "Yes", value: 1 },
                        { id: 0, name: "No", value: 0 },
                      ]}
                      placeholder="Withdrawal bank account configured"
                      isClearable={true}
                      value={value?.is_withdrawal_acc_configured}
                      name="is_withdrawal_acc_configured"
                      // value={formik.values.country_id}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
        }
      })}
      {!isMobile && (
        <div className={`px-[.3125rem]  ${loading && "shimmer-effect"}`}>
          <button
            onClick={() =>
              handleOnApplyFilters("apply", layout.isTabActive, {
                ...value,
                page: 1,
                timeStamp: new Date(),
              })
            }
            className={`apply-filter-common group inline-flex group items-center bg-violet-500 hover:bg-indigo-500 text-white   transition font-medium rounded text-sm13 px-2.5 py-0.5 false h-7 `}
          >
            Apply
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default CommonFilter;
