import { LayoutContext } from "components/core/LayoutProvider";
import DatePickerField from "components/formComponent/DatePickerField";
import DropDownField from "components/formComponent/DropDownField";
import MultiDropDownField from "components/formComponent/MultiDropDownField";
import { handleSelectDeselectAll, removeEmptyValues } from "helpers/Functions";
import _ from "lodash";
import { useContext, useMemo, useRef } from "react";
import { SellerLevelsProviderContex } from "../core/SellerLevelsProvider";

const MovementFilters = ({
  loading,
  setFiltersData,
  filterchipData,
  handleOnApplyButtonClick = () => {},
}: any) => {
  const layout = useContext(SellerLevelsProviderContex);
  const globalLayout = useContext(LayoutContext);
  const teamMemberSelectedRef = useRef<any>();
  const copyFilters = layout.commonFilter;
  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any = null
  ) => {
    if (teamMemberSelectedRef && teamMemberSelectedRef?.current) {
      teamMemberSelectedRef.current.blur();
    }
    handleSelectDeselectAll(
      data,
      fieldName,
      options,
      visibleOptions,
      layout.setSellerLevelMovementFilters
    );
  };

  const downUpgradeOptions = [
    {
      name: "Upgrade",
      id: 2,
    },
    {
      name: "Downgrade",
      id: 1,
    },
  ];

  const { page, per_page, ...rest } = layout.sellerLevelMovementFilters;

  const applyButtonDisabled = useMemo(() => {
    const obj1 = _.cloneDeep(layout.sellerLevelMovementFilters);
    const obj2 = _.cloneDeep(copyFilters);
    delete obj1.page;
    delete obj1.per_page;
    delete obj2.page;
    delete obj2.per_page;

    const removeEmptyvalue1 = removeEmptyValues(obj1);
    const removeEmptyvalue2 = removeEmptyValues(obj2);

    return _.isEqual(removeEmptyvalue1, removeEmptyvalue2);
  }, [layout.sellerLevelMovementFilters, copyFilters]);

  return (
    <div className={` rounded flex gap-4 w-full`}>
      <div
        className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem]`}
      >
        <div className={`${loading && "shimmer-effect"} rounded`}>
          <DatePickerField
            name="dateRange"
            placeholder="Transaction date"
            disabledKeyboardNavigation={false}
            value={layout.sellerLevelMovementFilters?.dateRange}
            isDateRange={true}
            handleOnChange={(data: any) => {
              layout.setSellerLevelMovementFilters((currentValue: any) => {
                return {
                  ...currentValue,
                  [data?.target?.name]: data?.target?.value,
                  page: 1,
                };
              });
            }}
            format="dd/MM/yy"
          />
        </div>
      </div>
      <div
        className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem]`}
      >
        <div className={`${loading && "shimmer-effect"} rounded`}>
          <MultiDropDownField
            name="team_id"
            classNamePrefix={"form_multiDropDown"}
            placeholder="Team members"
            value={layout.sellerLevelMovementFilters?.team_id || []}
            handleOnChange={(data: any) => {
              layout.setSellerLevelMovementFilters((prev: any) => {
                return {
                  ...prev,
                  team_id: data?.target?.value,
                  page: 1,
                };
              });
            }}
            options={globalLayout?.allowedAccess?.teams || []}
            // isMenuPortalTarget={orderIsLoading ? false : true}
            selectedRef={teamMemberSelectedRef}
            isMultiSelection={true}
            handleOnSelection={(data: any, visibleOptions: any) =>
              handleOnSelection(
                data,
                "team_id",
                globalLayout?.allowedAccess?.teams,
                visibleOptions
              )
            }
          />
        </div>
      </div>
      <div
        className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem]`}
      >
        <div className={`${loading && "shimmer-effect"} rounded`}>
          <DropDownField
            options={downUpgradeOptions}
            placeholder="Movement"
            // selectInputRef={ref}
            isClearable={false}
            name="is_upgrade"
            value={layout.sellerLevelMovementFilters?.is_upgrade}
            getOptionLabel="name"
            getOptionValue="id"
            handleOnChange={(data: any) => {
              layout.setSellerLevelMovementFilters((currentValue: any) => {
                return {
                  ...currentValue,
                  [data?.target?.name]: data?.target?.value,
                  page: 1,
                };
              });
            }}
          />
        </div>
      </div>
      <div className={`px-[.3125rem]  ${loading && "shimmer-effect"}`}>
        <button
          onClick={() =>
            handleOnApplyButtonClick("apply", {
              ...layout.sellerLevelMovementFilters,
              timeStamp: new Date(),
              page: 1,
            })
          }
          className={`apply-filter-common group inline-flex group items-center bg-violet-500 hover:bg-indigo-500 text-white   transition font-medium rounded text-sm13 px-2.5 py-0.5 false h-7 `}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default MovementFilters;
