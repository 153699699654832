import { size } from "lodash";
import moment from "moment";
import { createInvoiceNumber } from "pages/tx_account/core/functions";
import React, { useContext, useEffect, useMemo, useState } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import cross from "../../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../../components/ui/ProgressBardLoader";
import {
  addCommaInNumber,
  errorReflect,
  pluralize,
} from "../../../../helpers/Functions";
import { showAlert } from "../../../../helpers/ShowAlert";
import {
  SYMBOLS,
  TX_ACCOUNT_INVOICE_VIEW_DATA,
} from "../../../../helpers/const";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import { TxAccountContext } from "../../core/TxAccountProvider";
import {
  downloadPDF,
  invoiceViewAPI,
  saveInvoiceCommission,
} from "../../core/_request";
import CommissionPDFView from "../CommissionPDFView";
import DropdownViewPopup from "../DropdownViewPopup";
import PDFView from "../PDFView";
import PopupDetailBox from "../PopupDetailBox";
import { MultiExportToCsv } from "./MultiExportToCsv/MultiExportToCsv";
import RemoveOrders from "./RemoveOrders";
import ViewLeftTableWrapper from "./draft_view_invoice_component/ViewLeftTableWrapper";

const DraftViewInvoice = React.memo(() => {
  const layout = useContext(TxAccountContext);
  let invoiceView = layout.invoicesViewData;
  const { data, meta, id } = invoiceView;
  const isGroupInvoice = useMemo(
    () => data?.invoice_type === "group_invoice",
    [data]
  );
  let isDisabled = data?.invoice_status !== "Draft" ? true : false;
  const [PDFToggle, setPDFToggle] = useState<string>("");
  const [loading, setIsLoading] = useState<any>("");
  const [viewGroup, setViewGroup] = useState<any>({
    data: {
      order_id: "",
    },
    show: false,
  });

  const [isCommissionLoading, setIsCommissionLoading] =
    useState<boolean>(false);

  let excludeOrderInitial = {
    removeOrder: "",
    invoice_id: id,
    order_ids: [],
    allOrderIds: [],
  };

  const [state, setState] = useState<any>(excludeOrderInitial);

  const scroll = (scrollOffset: any) => {
    let replaceTable: any = document.getElementById("invoice-table-lazyload");
    replaceTable && (replaceTable.scrollLeft += scrollOffset);
  };

  // PDF HANDLE
  const handlePDFDownload = async (data: any, type: string, name: string) => {
    layout.setCustomLoader(true);
    const response = await downloadPDF(data).then((response: any) => {
      return new Blob([response], { type: "application/pdf" });
    });

    const url = window.URL.createObjectURL(response);

    if (type === "view") {
      setPDFToggle(url);
      window.open(url, "_blank");
      layout.setCustomLoader(false);
    } else {
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.setAttribute("download", name);
      anchor.click();
      window.URL.revokeObjectURL(url);
      layout.setCustomLoader(false);
    }
  };

  let { order_by, sort_order } = layout.invoicesFilter;

  const fetchMoreData = async (data: any, isSort: boolean) => {
    try {
      const response = await invoiceViewAPI({
        ...data,
        id,
        invoice_type: invoiceView?.data?.invoice_type,
      });
      if (response) {
        setIsLoading("");
        if (response?.data && size(response?.data) > 0) {
          if (data?.isGroup)
            setViewGroup((current: any) => {
              const invoiceDetails = response?.data?.orders?.find(
                (item: any) => item?.order_id === current?.data?.order_id
              );
              return {
                ...current,
                data: {
                  ...current?.data,
                  invoice_details: invoiceDetails?.invoice_details,
                },
                show: true,
              };
            });
          layout.setInvoicesViewData((pre: any) => ({
            ...pre,
            data: {
              ...pre.data,
              ...response?.data,
              orders: isSort
                ? response?.data?.orders
                : [...pre.data.orders, ...response?.data?.orders],
            },
            meta: response?.meta,
          }));
        } else if (response?.errors) {
          if ("title" in response?.errors) {
            showAlert(response?.errors?.title, true);
          } else {
            showAlert("Something went wrong", true);
          }
        }
      }
    } catch (error) {
      // Handle error here if needed
      console.error("Error fetching more data:", error);
      throw error; // Rethrow the error to be caught by the caller
    }
  };

  useEffect(() => {
    if (order_by) {
      setIsLoading("sorting");
      fetchMoreData({ page: 1, per_page: 20, order_by, sort_order }, true);
    }
  }, [order_by, sort_order]);

  const [height, setHeight] = useState<any | undefined>(undefined);
  // let timeout: any;
  // const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  // const renderCall = () => {
  //   timeout = setTimeout(() => {
  //     const divElement: any = document.querySelector("#invoice-table-lazyload");
  //     const thead = document.querySelector("#tableId11 table thead");
  //     const theadHeight: any = thead?.scrollHeight;

  //     const dataHeight = theadHeight + data?.orders?.length * 45;
  //     console.log(dataHeight, data?.orders?.length, "dataHeight");

  //     // setTimeout(() => {
  //     //   nomoreListingHeight = nomoreListing?.clientHeight;
  //     // }, 2000);
  //     if (divElement && isMobile) {
  //       if (divElement.offsetHeight) {
  //         console.log("object");
  //         let elemHeight = dataHeight;
  //         setHeight(elemHeight);
  //       } else {
  //         setTimeout(renderCall, 100);
  //       }
  //     }
  //   }, 1000);
  // };
  // useLayoutEffect(() => {
  //   renderCall();
  //   window.addEventListener("scroll", renderCall);
  //   // Clear the timeout in case the component unmounts or the effect re-runs
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  useEffect(() => {
    const thead = document.querySelector("#tableId11 table thead");
    const theadHeight: any = thead?.scrollHeight;

    const dataHeight = theadHeight + data?.orders?.length * 45;
    setHeight(dataHeight);
  });

  const createCommissionInvoice = (
    e: any,
    xerocontact: any,
    isGroup: any = false
  ) => {
    e?.preventDefault();
    const payload = {
      id: isGroup ? isGroup?.id : data?.id,
      invoice_type: isGroup?.type,
      contact_id: xerocontact?.value,
    };
    setIsCommissionLoading(true);
    saveInvoiceCommission(payload?.id, payload).then((response: any) => {
      if (response?.data?.status) {
        fetchMoreData(
          {
            page: 1,
            per_page: 20,
            order_by,
            sort_order,
            ...(isGroup && { isGroup: isGroup }),
          },
          true
        ).then(() => {
          setIsCommissionLoading(false);
        });
      } else {
        errorReflect(response);
        setIsCommissionLoading(false);
      }
    });
  };

  return (
    <div className="bg-white main-content-wrap">
      <div className="flex flex-col inner_wrap lg2:h-[calc(100vh-3.8125rem)]">
        <div className="flex border-b justify-between offcanvas-header">
          <h5
            className="text-sm15 font-semibold leading-normal mb-0 offcanvas-title px-5 py-2"
            id="offcanvasRightLabel"
          >
            {data?.invoice_status === "Draft" ? "Draft invoice" : "Invoice"} :{" "}
            {data?.invoice_number}
          </h5>

          {/* CLOSE DRAFT */}
          <button
            type="button"
            className="flex border-l justify-center w-9 box-content group items-center"
            data-tooltip-id={`close-draft`}
            onClick={() => {
              layout?.setInvoicesViewData(TX_ACCOUNT_INVOICE_VIEW_DATA);
              setState(excludeOrderInitial);
              layout?.setInvoicesFilter((current: any) => {
                return {
                  ...current,
                  page: 1,
                  per_page: 20,
                  order_by: "",
                  sort_order: "asc",
                };
              });
            }}
          >
            <TooltipPortal
              id={`close-draft`}
              content={"Close"}
              place="left"
              className="shadow text-center text-xs !bg-white !opacity-100 !px-1.5 !py-0.5 cursor-default font-medium max-w-[9rem] z-100"
            />
            <IKTSVG
              path={cross}
              className="h-2.5 w-2.5 fill-violet-500 group-hover:fill-indigo-500 transition"
              svgClassName="w-2.5 h-2.5"
            />
          </button>
        </div>
        <div className="flex flex-col h-full w-full max-h-full offcanvas-body overflow-hidden">
          <div className="flex flex-wrap h-full inner_body">
            {/* LEFT SIDE - TABLE */}
            <ViewLeftTableWrapper
              data={data}
              loading={loading}
              isGroupInvoice={isGroupInvoice}
              state={state}
              meta={meta}
              viewGroup={viewGroup}
              fetchMoreData={fetchMoreData}
              setIsLoading={setIsLoading}
              setViewGroup={setViewGroup}
            />

            {/* RIGHT SIDE -  DETAILS */}
            <div className="border-r h-full p-5 w-full lg2:w-1/2 overflow-auto right_body scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-violet-800">
              <div className="flex justify-between mb-5 totle_order">
                <h5 className="text-sm14 font-semibold leading-4">
                  Invoice details
                </h5>
              </div>
              <div className="right_block">
                <div className="flex flex-wrap -mx-2.5 after_border pb-5">
                  <PopupDetailBox
                    label="Invoice status"
                    headerValue={
                      data?.invoice_status ? data?.invoice_status : "-"
                    }
                    isDisabled={data?.invoice_status === "Draft" ? true : false}
                    disabledColor={
                      data?.invoice_status === "Awaiting Payment"
                        ? "danger"
                        : data?.invoice_status === "Paid"
                        ? "success"
                        : ""
                    }
                    successValues={data?.payment_date}
                  />
                </div>
                <div className={`flex flex-wrap gap-y-5 pt-5 -mx-2.5`}>
                  {/* MARKETPLACE */}
                  <PopupDetailBox
                    label="Marketplace"
                    headerValue={data?.marketplace_name}
                    isDisabled={isDisabled}
                  />

                  {/* TEAM DROPDOWN */}
                  <DropdownViewPopup
                    isDisabled={isDisabled}
                    options={data?.teams}
                    value={data?.total_teams_count}
                  />

                  {/* EVENT DATE */}
                  <PopupDetailBox
                    label="Event date"
                    headerValue={
                      data?.min_event_date === data?.max_event_date
                        ? `${
                            data?.min_event_date
                              ? moment(data?.min_event_date).format(
                                  "DD/MM/YYYY"
                                )
                              : "N/A"
                          }`
                        : `${
                            data?.min_event_date
                              ? moment(data?.min_event_date).format(
                                  "DD/MM/YYYY"
                                ) + " - "
                              : "N/A"
                          } ${
                            data?.max_event_date
                              ? moment(data?.max_event_date).format(
                                  "DD/MM/YYYY"
                                )
                              : ""
                          }`
                    }
                    isDisabled={isDisabled}
                  />

                  {/* ORDER STATUS */}
                  <PopupDetailBox
                    label="Order status"
                    headerValue={data?.order_status}
                    isDisabled={isDisabled}
                  />

                  {/* ORDERS */}
                  <PopupDetailBox
                    label={pluralize(data?.total_orders || 0, "Order")}
                    headerValue={data?.total_orders}
                    countValue={""}
                    isDisabled={isDisabled}
                  />

                  {/* INVOICE VALUE */}
                  <PopupDetailBox
                    label="Invoice value"
                    headerValue={`${SYMBOLS[data?.currency]}${addCommaInNumber(
                      data?.invoice_value
                    )}`}
                    countValue=""
                    isDisabled={isDisabled}
                  />
                </div>
              </div>

              {/* REMOVE ORDERS */}
              {data?.total_orders > 1 &&
                data?.invoice_status !== "Paid" &&
                !isGroupInvoice && (
                  <RemoveOrders
                    state={state}
                    setState={setState}
                    executeListAPI={() =>
                      fetchMoreData({ page: 1, per_page: 20 }, true)
                    }
                  />
                )}

              {/* GROUP INVOICE */}
              {viewGroup?.data?.order_id && isGroupInvoice ? (
                <>
                  {/* PDF COMPONENT */}
                  <PDFView
                    fileName={`tixstock-invoice-${createInvoiceNumber(
                      viewGroup?.data?.invoice_details
                    )}-${data?.invoice_status
                      ?.toLowerCase()
                      .replace(/ /g, "-")}.pdf`}
                    isLoading={false}
                    draftKey={viewGroup?.data?.invoice_details?.xero_invoice_id}
                    isDownloadBtn={true}
                    downloadPDF={(data: any, type: string, name: string) => {
                      handlePDFDownload(data, type, name);
                    }}
                  />
                </>
              ) : (
                !isGroupInvoice && (
                  <>
                    {/* PDF COMPONENT */}
                    <PDFView
                      fileName={`tixstock-invoice-${
                        data?.invoice_number
                      }-${data?.invoice_status
                        ?.toLowerCase()
                        .replace(/ /g, "-")}.pdf`}
                      isLoading={false}
                      draftKey={data?.xero_invoice_id}
                      isDownloadBtn={true}
                      downloadPDF={(data: any, type: string, name: string) => {
                        handlePDFDownload(data, type, name);
                      }}
                    />
                  </>
                )
              )}

              {/* Commission PDF COMPONENT */}
              <hr className="my-[1.875rem] border-gray-650"></hr>
              <CommissionPDFView
                fileName={
                  data?.is_commission_invoice
                    ? `tixstock-commission-invoice-${
                        data?.invoice_number
                      }-${data?.invoice_status
                        ?.toLowerCase()
                        .replace(/ /g, "-")}.pdf`
                    : `Save commission ${isGroupInvoice ? "bulk " : ""}invoice`
                }
                isLoading={isCommissionLoading}
                draftKey={data?.xero_commission_invoice_id}
                isDownloadBtn={data?.is_commission_invoice}
                downloadPDF={(data: any, type: string, name: string) => {
                  handlePDFDownload(data, type, name);
                }}
                saveCommissionInvoice={createCommissionInvoice}
                title={
                  data?.is_commission_invoice
                    ? `View ${isGroupInvoice ? "bulk " : ""}commission invoice`
                    : `Commission ${isGroupInvoice ? "bulk " : ""}invoice`
                }
                isGenerate={!data?.is_commission_invoice}
              />

              {/* BULK GENERATE COMMISSION */}
              {/* {isGroupInvoice && !data?.is_commission_invoice ? (
                <>
                  <hr className="my-[1.875rem] border-gray-650"></hr>
                  <CommissionPDFView
                    fileName={
                      data?.is_commission_invoice
                        ? `tixstock-commission-invoice-${
                            data?.invoice_number
                          }-${data?.invoice_status
                            ?.toLowerCase()
                            .replace(/ /g, "-")}.pdf`
                        : "Save bulk commission invoice"
                    }
                    isLoading={isCommissionLoading?.bulkCommission}
                    draftKey={data?.xero_commission_invoice_id}
                    isDownloadBtn={data?.is_commission_invoice}
                    downloadPDF={(data: any, type: string, name: string) => {
                      handlePDFDownload(data, type, name);
                    }}
                    saveCommissionInvoice={createCommissionInvoice}
                    title={
                      data?.is_commission_invoice
                        ? "View bulk commission invoice"
                        : "Bulk Commission invoice"
                    }
                    isGenerate={!data?.is_commission_invoice}
                    loaderMessage="Saving bulk commission invoice"
                  />
                </>
              ) : null} */}
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <footer className="flex flex-wrap bg-white border border-b border-t justify-between bottom-0 items-center md:flex-nowrap min-h-[3.125rem] px-5 py-1 sticky z-20">
          <div className="flex flex-wrap justify-between w-full gap-2.5 items-center my-2">
            <MultiExportToCsv />
          </div>
        </footer>
      </div>

      {/* LOADER */}
      {layout?.customLoader && <ProgressBardLoader secondCounter={2.5} />}
    </div>
  );
});

export default DraftViewInvoice;
