import { convertIntoErrorMessage } from "helpers/Functions";
import { showAlert } from "helpers/ShowAlert";
import { size } from "lodash";
import { useContext } from "react";
import {
  TX_ACCOUNT_INVOICE_INITIAL_FILTER,
  TX_ACCOUNT_ORDER_DRAFT_INITIAL_STATE,
  TX_ACCOUNT_ORDER_INITIAL_FILTER,
  TX_ACCOUNT_PAYOUT_INTIAL_FILTER,
} from "../../../helpers/const";
import { TxAccountContext } from "../core/TxAccountProvider";
import { saveOrderInvoice } from "../core/_request";

const DraftInvoiceFooter = ({
  commonConfirmation,
  setCommonConfirmation,
  excludeOrderValue,
  ordersCount,
}: any) => {
  const layout = useContext(TxAccountContext);
  let disableGenerateDraft =
    size(excludeOrderValue?.excludeOrderFilterChip) > 0 ||
    commonConfirmation?.generate_draft_invoice_next_step_disabled ||
    ordersCount === 0 ||
    !String(excludeOrderValue?.reference || "").trim() ||
    !String(excludeOrderValue?.invoice_number || "").trim() ||
    !excludeOrderValue?.invoiceDate ||
    !excludeOrderValue?.dueDate ||
    excludeOrderValue?.dueDate < excludeOrderValue?.invoiceDate;

  // CONFIRMED DRAFT
  const handleConfirmedDraft = () => {
    let rightBtn: any = document.getElementById("right_body");
    rightBtn.scrollTo({
      top: rightBtn.scrollHeight,
      behavior: "smooth",
    });

    setCommonConfirmation((current: any) => ({
      ...current,
      generate_draft_invoice_next_step_disabled: true,
    }));

    // SAVE INVOICE THROUGH - API
    saveOrderInvoice({
      ...layout.orderFilters,
      exclude_order_ids:
        commonConfirmation?.listing_data?.filterData?.excludesOrderId,
      invoice_number: excludeOrderValue?.invoice_number,
      prefix_invoice_number: excludeOrderValue?.prefix_invoice_number,
      reference: excludeOrderValue?.reference,
      invoice_date: excludeOrderValue?.invoiceDate,
      due_date: excludeOrderValue?.dueDate,
    }).then((res: any) => {
      if (res?.data?.status) {
        setCommonConfirmation((current: any) => ({
          ...current,
          finish: true,
          draftData: {
            status: res?.data?.invoice_status,
            invoice_number: res?.data?.invoice_number,
            invoice_id: res?.data?.invoice_id,
            invoice_primary_id: res?.data?.invoice_primary_id,
            invoice_date: excludeOrderValue?.invoiceDate,
            due_date: excludeOrderValue?.dueDate,
            invoice_type: res?.data?.invoice_type,
          },
          listing_data: {
            ...current?.listing_data,
            filterData: {
              ...current?.listing_data?.filterData,
              orders: {
                ...current?.listing_data?.filterData?.orders,
                excludeOrders: "",
              },
              teamsOption: {
                ...current?.listing_data?.filterData?.teamsOption,
                excludeValue: "",
              },
              invoiceValue: {
                ...current?.listing_data?.filterData?.invoiceValue,
                excludeInvoice: "",
              },
            },
          },
        }));

        if (res?.data?.invoice_type === "group_invoice") {
          layout.setInvoicesFilter({
            ...TX_ACCOUNT_INVOICE_INITIAL_FILTER,
            query: res?.data?.invoice_id,
            openView: true,
          });

          layout.setOrderFilters(TX_ACCOUNT_ORDER_INITIAL_FILTER);

          layout.setCommonFilter((pre: any) => {
            return {
              orderFilters: TX_ACCOUNT_ORDER_INITIAL_FILTER,
              orderPayoutSeller: TX_ACCOUNT_PAYOUT_INTIAL_FILTER,
              invoicesFilter: {
                ...TX_ACCOUNT_INVOICE_INITIAL_FILTER,
                query: res?.data?.invoice_id,
                openView: true,
              },
            };
          });
          layout?.setCommonConfirmation(TX_ACCOUNT_ORDER_DRAFT_INITIAL_STATE);
          layout.setTabActive("Invoices");
        }
      } else {
        showAlert(convertIntoErrorMessage(res), true);
        setCommonConfirmation((current: any) => {
          return {
            ...current,
            generate_draft_invoice_next_step: false,
            generate_draft_invoice_next_step_disabled: false,
          };
        });
      }
    });
  };

  return (
    <footer className="sticky bottom-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between px-5 py-1 bg-white border border-t z-20 min-h-[3.125rem]">
      <div className={`flex flex-wrap w-full justify-end `}>
        {/* DRAFT - GENERATE BUTTON */}
        {commonConfirmation?.generate_draft_invoice_status &&
        commonConfirmation?.generate_draft_invoice_next_step &&
        commonConfirmation?.generate_draft_invoice_next_step_disabled &&
        commonConfirmation?.finish ? (
          // FINISH
          <div className="right-btns flex items-center gap-2.5 anim-fade">
            <button
              type="button"
              className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
              onClick={() => {
                setCommonConfirmation(TX_ACCOUNT_ORDER_DRAFT_INITIAL_STATE);
                layout?.setOrderFilters(TX_ACCOUNT_ORDER_INITIAL_FILTER);
                layout.setTabActive("Invoices");
              }}
            >
              Finish
            </button>
          </div>
        ) : commonConfirmation?.generate_draft_invoice_status &&
          commonConfirmation?.generate_draft_invoice_next_step &&
          !commonConfirmation?.generate_draft_invoice_next_step_disabled &&
          !commonConfirmation?.finish ? (
          // CONFIRM WITH DRAFT INVOCIE
          <div className="flex flex-wrap items-center gap-2.5  text-sm13 anim-fade">
            <span>
              Do you confirm the{" "}
              <span className="font-semibold">DRAFT INVOICE</span> details are
              correct?
            </span>
            <button
              className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
                        bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-[.0625rem] leading-5"
              type="button"
              onClick={() =>
                setCommonConfirmation((current: any) => {
                  return {
                    ...current,
                    generate_draft_invoice_next_step: false,
                  };
                })
              }
            >
              Cancel
            </button>
            <button
              type="button"
              className="group inline-flex group items-center   transition font-medium rounded px-2.5 py-0.5 leading-5  bg-violet-500 hover:bg-indigo-500 text-white hover:text-white"
              onClick={handleConfirmedDraft}
            >
              Confirm
            </button>
          </div>
        ) : (
          commonConfirmation?.generate_draft_invoice_status &&
          // !commonConfirmation?.generate_draft_invoice_next_step &&
          !commonConfirmation?.finish && (
            <div className={`right-btns flex items-center gap-2.5 anim-fade`}>
              <button
                type="button"
                className={`group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5 border  ${
                  size(excludeOrderValue?.excludeOrderFilterChip) > 0 ||
                  commonConfirmation?.generate_draft_invoice_next_step_disabled
                    ? "pointer-events-none bg-gray-100 text-gray-400"
                    : "hover:bg-indigo-500 hover:text-white"
                }`}
                onClick={() =>
                  setCommonConfirmation(TX_ACCOUNT_ORDER_DRAFT_INITIAL_STATE)
                }
              >
                Cancel
              </button>
              <button
                type="button"
                className={`group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                  disableGenerateDraft
                    ? "pointer-events-none bg-gray-100 text-gray-400"
                    : "text-white hover:text-white bg-violet-500 hover:bg-indigo-500"
                }`}
                onClick={() =>
                  !disableGenerateDraft &&
                  setCommonConfirmation((current: any) => {
                    return {
                      ...current,
                      generate_draft_invoice_next_step: true,
                    };
                  })
                }
              >
                Generate draft invoice
              </button>
            </div>
          )
        )}
      </div>
    </footer>
  );
};

export default DraftInvoiceFooter;
