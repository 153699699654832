import { isMobile } from "helpers/const";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import close from "../../../assets/media/icons/other_icons/Clear.svg";
import filter from "../../../assets/media/icons/other_icons/filter.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import DatePickerField from "../../../components/formComponent/DatePickerField";
import MultiDropDownField from "../../../components/formComponent/MultiDropDownField";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { KTSVG } from "../../../components/ui/KTSVG";
import {
  handleSelectDeselectAll,
  removeEmptyValues,
} from "../../../helpers/Functions";
import { NotificationsContext } from "../core/NotificationsProvider";

const ActivityFilters = ({
  queryActivity,
  options,
  handleQuery,
  paginateData,
  debouncedSearchActivity,
  userDetailInfo,
  notificationsLoading,
  handleOnApplyFilter = () => {},
}: any) => {
  const layout = useContext(NotificationsContext);
  const globalLayout = useContext(LayoutContext);
  const access = globalLayout.allowedAccess?.allowed_access;
  const [teamOptions, setTeamOptions] = useState<any>();
  const teamSelectedRef: any = useRef<any>();
  const teamMemberSelectedRef: any = useRef<any>();
  const activityTypeSelectedRef: any = useRef<any>();
  const commonFilter = layout.commonFilter.activitiesFilterHandle;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );
  let inputRef = useRef<any>();
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;

  const handleRefresh = () => {
    inputRef.current.value = "";
    handleQuery(undefined);
    layout.setDateRange("");
    layout.setActivitiesFilterHandle((currentVal: any) => {
      return {
        ...currentVal,
        teammembers: [],
        activities: [],
        team_id: [],
        new_activities: null,
      };
    });
    handleOnApplyFilter("remove", {
      per_page: 20,
      page: 1,
    });
  };

  // Function to get index of dragged Filter item
  const getFilterIndex = (id: any) => {
    let index = layout.draggedItems.findIndex((item: any) => item.id === id);
    return index + 1;
  };
  const getFilterIndexForCheckbox = (id: any) => {
    let index = layout.filterItems.findIndex((item: any) => item.id === id);
    return index;
  };

  useEffect(() => {
    if (userDetailInfo) {
      setTeamOptions(userDetailInfo);
    }
  }, [userDetailInfo]);

  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any
  ) => {
    if (teamSelectedRef && teamSelectedRef?.current) {
      teamSelectedRef.current.blur();
    }
    if (teamMemberSelectedRef && teamMemberSelectedRef?.current) {
      teamMemberSelectedRef.current.blur();
    }
    if (activityTypeSelectedRef && activityTypeSelectedRef?.current) {
      activityTypeSelectedRef.current.blur();
    }

    handleSelectDeselectAll(
      data,
      fieldName,
      options,
      visibleOptions,
      layout.setActivitiesFilterHandle
    );
  };

  let activityOptions = options?.activities?.map((item: any) => {
    return {
      id: item.id,
      name: (item?.name).charAt(0).toUpperCase() + item.name.slice(1),
    };
  });

  function ToggleElemClose() {
    const submenuWrapper: any = document.querySelector("#filtersMob");
    submenuWrapper.classList.remove("activeFIlter");
    document.body.classList.remove("overflow-hidden");
  }
  useEffect(() => {
    const toggleButton: any = document.getElementById("filterBtn");
    const submenuWrapper: any = document.querySelector("#filtersMob");
    const closePopup: any = document.getElementById("closePopup");
    const ToggleElem = function () {
      if (submenuWrapper.classList.contains("activeFIlter")) {
        submenuWrapper.classList.remove("activeFIlter");
      } else {
        submenuWrapper.classList.add("activeFIlter");
        document.body.classList.add("overflow-hidden");
      }
    };
    const ToggleElemClose = function () {
      submenuWrapper.classList.remove("activeFIlter");
      document.body.classList.remove("overflow-hidden");
    };

    toggleButton.addEventListener("click", ToggleElem);
    closePopup.addEventListener("click", ToggleElemClose);
    return () => {
      toggleButton.removeEventListener("click", ToggleElem);
      closePopup.removeEventListener("click", ToggleElemClose);
    };
  }, []);

  const clearDisable =
    (layout?.activitiesFilterHandle?.teammembers &&
      layout?.activitiesFilterHandle?.teammembers.length > 0) ||
    (layout?.activitiesFilterHandle?.activities &&
      layout?.activitiesFilterHandle?.activities.length > 0) ||
    (layout?.dateRange &&
      layout?.dateRange?.target?.value &&
      layout?.dateRange?.target?.value?.startDate !== undefined &&
      layout?.dateRange?.target?.value?.endDate !== undefined) ||
    (queryActivity !== undefined && debouncedSearchActivity) ||
    layout?.activitiesFilterHandle?.team_id?.length > 0 ||
    layout?.activitiesFilterHandle?.new_activities === 1;

  function getFilteredValue(type: any, godadminValue: any, normalValue: any) {
    return type ? godadminValue : normalValue;
  }

  const applybuttonDisabled =
    (commonFilter?.teammembers && commonFilter?.teammembers.length > 0) ||
    (commonFilter?.activities && commonFilter?.activities.length > 0) ||
    (commonFilter?.dateRange &&
      commonFilter?.dateRange?.target?.value &&
      commonFilter?.dateRange?.target?.value?.startDate !== undefined &&
      commonFilter?.dateRange?.target?.value?.endDate !== undefined) ||
    commonFilter?.debouncedSearchActivity ||
    commonFilter?.team_id?.length > 0 ||
    commonFilter?.new_activities === 1;

  const applyButtonDisabled = useMemo(() => {
    const obj1 = _.cloneDeep({
      ...layout?.activitiesFilterHandle,
      dateRange: layout?.dateRange,
      debouncedSearchActivity,
    });
    const obj2 = _.cloneDeep(commonFilter);
    delete obj1.page;
    delete obj1.per_page;
    delete obj2.page;
    delete obj2.per_page;

    const removeEmptyvalue1 = removeEmptyValues(obj1);
    const removeEmptyvalue2 = removeEmptyValues(obj2);

    return _.isEqual(removeEmptyvalue1, removeEmptyvalue2);
  }, [
    layout?.activitiesFilterHandle,
    layout?.dateRange,
    debouncedSearchActivity,
    commonFilter,
  ]);

  return (
    <>
      <div className="filtersMob max-md:flex max-md:flex-col" id={"filtersMob"}>
        <div
          className="flex flex-wrap border-t gap-y-2.5 py-4 px-[.9375rem] bg-white  max-md:pt-14 max-md:flex-1 max-md:overflow-auto max-md:content-start"
          id={`filters_block`}
        >
          <div className="closePopup hidden max-md:flex" id="closePopup">
            <IKTSVG
              path={close}
              className="w-10 absolute top-[1px] right-0 h-10 flex items-center justify-center cursor-pointer z-[1] bg-white"
              svgClassName="w-4 h-4"
            />
          </div>
          {/*Search Notifications*/}
          <div
            className={`w-full lg2:w-1/3 lg:w-1/2 xl:w-[25%]  px-[.3125rem]  `}
          >
            <div
              className={`relative rounded flex-1 max-w-full md:mb-0 ${
                notificationsLoading && "shimmer-effect"
              }`}
            >
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <button>
                  <KTSVG
                    className="fill-violet-500"
                    svgClassName="h-3.5 w-3.5"
                    path="other_icons/search-violet.svg"
                  />
                </button>
              </div>
              <input
                ref={inputRef}
                type="text"
                name="query"
                id="simple-search"
                className="bg-indigo-500/5 border font-medium border-indigo-500/10 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-[1.875rem] pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                placeholder="Search Activity"
                onChange={(e: any) => handleQuery(e.target.value)}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter" && isGodAdmin) {
                    handleOnApplyFilter("apply", {
                      ...layout.activitiesFilterHandle,
                      dateRange: layout.dateRange,
                      debouncedSearchActivity: e.target.value,
                    });
                  }
                }}
              />
            </div>
          </div>

          {isGodAdmin &&
            layout.filterItems[`${getFilterIndexForCheckbox(0)}`]?.checked && (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] px-[.3125rem] }-${getFilterIndex(
                  0
                )} ${notificationsLoading && "shimmer-effect"} `}
              >
                <MultiDropDownField
                  isCapital={true}
                  isValueCapital={true}
                  options={teamOptions}
                  classNamePrefix={"form_multiDropDown"}
                  placeholder={"All Teams"}
                  isClearable={true}
                  name="team_id"
                  isMultiSelection={true}
                  selectedRef={teamSelectedRef}
                  handleOnSelection={(data: any, visibleOptions: any) =>
                    handleOnSelection(
                      data,
                      "team_id",
                      teamOptions,
                      visibleOptions
                    )
                  }
                  handleOnChange={(data: any) =>
                    layout.setActivitiesFilterHandle((currentValue: any) => {
                      return {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.value,
                      };
                    })
                  }
                  label="Teams"
                  value={layout.activitiesFilterHandle?.team_id}
                />
              </div>
            )}

          {/*Activity Filters*/}
          {!userSpecific &&
            layout.filterItems[`${getFilterIndexForCheckbox(1)}`]?.checked && (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] px-[.3125rem] }-${getFilterIndex(
                  1
                )} ${notificationsLoading && "shimmer-effect"}`}
              >
                <MultiDropDownField
                  name="teammembers"
                  classNamePrefix={"form_multiDropDown"}
                  placeholder="Team members"
                  value={layout.activitiesFilterHandle?.teammembers}
                  isMultiSelection={true}
                  selectedRef={teamMemberSelectedRef}
                  handleOnSelection={(data: any, visibleOptions: any) =>
                    handleOnSelection(
                      data,
                      "teammembers",
                      options?.team_members,
                      visibleOptions
                    )
                  }
                  handleOnChange={(data: any) =>
                    layout.setActivitiesFilterHandle((currentValue: any) => {
                      return {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.value,
                      };
                    })
                  }
                  options={options?.team_members}
                />
              </div>
            )}
          {/* {layout.filterItems[`${getFilterIndexForCheckbox(2)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] px-[.3125rem] }-${getFilterIndex(
                2
              )} `}
            >
              <div className={`${notificationsLoading && "shimmer-effect"}`}>
                <DatePickerField
                  name="dateRange"
                  placeholder="Date range"
                  disabledKeyboardNavigation={false}
                  value={layout.dateRange?.target?.value}
                  isDateRange={true}
                  handleOnChange={(data: any) => layout.setDateRange(data)}
                />
              </div>
            </div>
          )} */}

          {layout.filterItems[`${getFilterIndexForCheckbox(2)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] px-[.3125rem] }-${getFilterIndex(
                2
              )} ${notificationsLoading && "shimmer-effect"}`}
            >
              <DatePickerField
                name="dateRange"
                placeholder="Date range"
                disabledKeyboardNavigation={false}
                value={layout.dateRange?.target?.value}
                isDateRange={true}
                handleOnChange={(data: any) => layout.setDateRange(data)}
              />
            </div>
          )}
          {layout.filterItems[`${getFilterIndexForCheckbox(3)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] px-[.3125rem] }-${getFilterIndex(
                3
              )} ${notificationsLoading && "shimmer-effect"}  `}
            >
              <MultiDropDownField
                name="activities"
                classNamePrefix={"form_multiDropDown"}
                placeholder="Activity type"
                value={layout.activitiesFilterHandle?.activities}
                isMultiSelection={true}
                selectedRef={teamMemberSelectedRef}
                handleOnSelection={(data: any, visibleOptions: any) =>
                  handleOnSelection(
                    data,
                    "activities",
                    activityOptions,
                    visibleOptions
                  )
                }
                handleOnChange={(data: any) =>
                  layout.setActivitiesFilterHandle((currentValue: any) => {
                    return {
                      ...currentValue,
                      [data?.target?.name]: data?.target?.value,
                    };
                  })
                }
                options={activityOptions}
              />
            </div>
          )}

          {isGodAdmin && !isMobile && (
            <div
              className={`px-[.3125rem] order-7 ${
                notificationsLoading && "shimmer-effect"
              }`}
            >
              <button
                onClick={() =>
                  handleOnApplyFilter("apply", {
                    ...layout.activitiesFilterHandle,
                    dateRange: layout.dateRange,
                    debouncedSearchNotifications: debouncedSearchActivity,
                    timeStamp: new Date(),
                  })
                }
                className={` apply-filter-common group inline-flex group items-center bg-violet-500 hover:bg-indigo-500 text-white   transition font-medium rounded text-sm13 px-2.5 py-0.5 false h-7 `}
              >
                Apply
              </button>
            </div>
          )}
        </div>
        <div className="bottomBtns hidden max-md:flex sticky bottom-0 left-0 w-full px-5 py-2.5 shadow-3xl">
          <button
            className={`border  hover:border-indigo-500  hover:text-white  hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5 ${
              clearDisable
                ? "bg-white text-violet-800 border-gray-300"
                : "bg-gray-100 text-gray-400 pointer-events-none border-gray-100"
            }`}
            onClick={() => {
              handleRefresh();
            }}
          >
            Clear
          </button>
          <button
            className="border border-violet-500 hover:border-indigo-500 text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
            onClick={() => {
              handleOnApplyFilter("apply", {
                ...layout.activitiesFilterHandle,
                dateRange: layout.dateRange,
                debouncedSearchNotifications: debouncedSearchActivity,
              });
              ToggleElemClose();
            }}
          >
            Apply
          </button>
        </div>
      </div>

      <div className="flex flex-wrap customStyle relative bg-white border-t max-sm:justify-between">
        <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center max-sm:w-[calc(100%-5.9375rem)]">
          <span
            className={`!rounded ${notificationsLoading && "shimmer-effect"} `}
          >
            <span
              className={`${
                notificationsLoading && "opacity-0 inline-block "
              }}`}
            >
              {paginateData?.total
                ? paginateData?.total?.toLocaleString()
                : "0"}
              {paginateData?.total > 1 ? " Logs" : " Log"}
            </span>
          </span>
        </div>
        <div className="flex items-center justify-center sm:justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full max-sm:flex-1 max-sm:order-3 max-sm:border-t max-sm:justify-start">
          {notificationsLoading ? (
            <div className="flex gap-5">
              <div
                className={`flex !rounded overflow-hidden !rounded ${
                  notificationsLoading && "shimmer-effect"
                }`}
                style={{ height: "1.625rem", width: "9.375rem" }}
              >
                &nbsp;
              </div>
            </div>
          ) : (
            <>
              {/* REFRESH ALL - CHIP */}
              {(isGodAdmin ? applybuttonDisabled : clearDisable) ? (
                <button
                  type="button"
                  className="group border text-center bg-white rounded min-w-[1.5rem] w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition flex items-center justify-center"
                  onClick={() => {
                    handleRefresh();
                  }}
                  id="reset"
                >
                  <Tooltip
                    anchorId={`reset`}
                    content="Reset"
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 mt-1.5"
                  />
                  <KTSVG
                    path="other_icons/refresh.svg"
                    className="fill-violet-500 group-hover:fill-white transition"
                    svgClassName="w-3 h-3"
                  />
                </button>
              ) : null}

              {getFilteredValue(
                isGodAdmin,
                commonFilter?.debouncedSearchActivity,
                debouncedSearchActivity
              ) && (
                <div
                  className={`${
                    queryActivity
                      ? "flex py-1 px-2.5 text-xs border rounded-full"
                      : "hidden"
                  } `}
                >
                  {queryActivity}
                  <button
                    type="button"
                    className="ml-2"
                    onClick={() => {
                      inputRef.current.value = "";
                      handleQuery(undefined);
                      handleOnApplyFilter("remove", {
                        ...commonFilter,
                        debouncedSearchActivity: "",
                        page: 1,
                      });
                    }}
                  >
                    <KTSVG
                      path="standard_icons/cross.svg"
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}

              {getFilteredValue(
                isGodAdmin,
                commonFilter,
                layout.activitiesFilterHandle
              )?.new_activities === 1 && (
                <div
                  className={`${"flex py-1 px-2.5 text-xs border rounded-full"} `}
                >
                  {"New activites"}
                  <button
                    type="button"
                    className="ml-2"
                    onClick={() => {
                      layout.setActivitiesFilterHandle((currentVal: any) => {
                        return {
                          ...currentVal,
                          new_activities: null,
                        };
                      });
                      handleOnApplyFilter("remove", {
                        ...commonFilter,
                        new_activities: null,
                        page: 1,
                      });
                    }}
                  >
                    <KTSVG
                      path="standard_icons/cross.svg"
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}

              {/* TEAM */}
              {getFilteredValue(
                isGodAdmin,
                commonFilter,
                layout.activitiesFilterHandle
              )?.team_id?.length > 0 &&
                getFilteredValue(
                  isGodAdmin,
                  commonFilter,
                  layout.activitiesFilterHandle
                )?.team_id.map((user: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                        {user?.name}
                        <button
                          type="button"
                          className="ml-2"
                          onClick={(e: any) => {
                            layout.setActivitiesFilterHandle(
                              (currentVal: any) => {
                                let team_id = currentVal?.team_id?.filter(
                                  (user: any, indexNumber: number) =>
                                    index !== indexNumber
                                );
                                handleOnApplyFilter("remove", {
                                  ...commonFilter,
                                  team_id: team_id,
                                  page: 1,
                                });
                                return {
                                  ...currentVal,
                                  team_id: team_id,
                                };
                              }
                            );
                          }}
                        >
                          <KTSVG
                            path="standard_icons/cross.svg"
                            svgClassName="h-2 w-2 hover:fill-indigo-500"
                          />
                        </button>
                      </div>
                    </React.Fragment>
                  );
                })}

              {/* TEAM MEMBER */}
              {getFilteredValue(
                isGodAdmin,
                commonFilter,
                layout.activitiesFilterHandle
              )?.teammembers?.length > 0 &&
                getFilteredValue(
                  isGodAdmin,
                  commonFilter,
                  layout.activitiesFilterHandle
                )?.teammembers.map((user: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                        {user?.name}
                        <button
                          type="button"
                          className="ml-2"
                          onClick={(e: any) => {
                            layout.setActivitiesFilterHandle(
                              (currentVal: any) => {
                                let teammembers =
                                  currentVal?.teammembers?.filter(
                                    (user: any, indexNumber: number) =>
                                      index !== indexNumber
                                  );
                                handleOnApplyFilter("remove", {
                                  ...commonFilter,
                                  teammembers: teammembers,
                                  page: 1,
                                });
                                return {
                                  ...currentVal,
                                  teammembers: teammembers,
                                };
                              }
                            );
                          }}
                        >
                          <KTSVG
                            path="standard_icons/cross.svg"
                            svgClassName="h-2 w-2 hover:fill-indigo-500"
                          />
                        </button>
                      </div>
                    </React.Fragment>
                  );
                })}
              {getFilteredValue(isGodAdmin, commonFilter, {
                dateRange: layout?.dateRange,
              })?.dateRange &&
                getFilteredValue(isGodAdmin, commonFilter, {
                  dateRange: layout?.dateRange,
                })?.dateRange?.target?.value?.startDate !== undefined &&
                getFilteredValue(isGodAdmin, commonFilter, {
                  dateRange: layout?.dateRange,
                })?.dateRange?.target?.value?.endDate !== undefined && (
                  <div className="flex py-1 px-2.5 text-xs border rounded-full">
                    {moment(layout?.dateRange?.target?.value?.startDate).format(
                      "DD/MM/YYYY"
                    )}
                    <span className="px-2">-</span>
                    {moment(layout?.dateRange?.target?.value?.endDate).format(
                      "DD/MM/YYYY"
                    )}
                    <button
                      type="button"
                      className="ml-2"
                      onClick={() => {
                        layout.setDateRange("");
                        handleOnApplyFilter("remove", {
                          ...commonFilter,
                          dateRange: "",
                          page: 1,
                        });
                      }}
                    >
                      <KTSVG
                        path="standard_icons/cross.svg"
                        svgClassName="h-2 w-2 hover:fill-indigo-500"
                      />
                    </button>
                  </div>
                )}
              {getFilteredValue(
                isGodAdmin,
                commonFilter,
                layout.activitiesFilterHandle
              )?.activities?.length > 0 &&
                getFilteredValue(
                  isGodAdmin,
                  commonFilter,
                  layout.activitiesFilterHandle
                )?.activities?.map((user: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="flex py-1 px-2.5 text-xs border rounded-full">
                        {user?.name}
                        <button
                          type="button"
                          className="ml-2"
                          onClick={(e: any) => {
                            layout.setActivitiesFilterHandle(
                              (currentVal: any) => {
                                let activities = currentVal?.activities?.filter(
                                  (user: any, indexNumber: number) =>
                                    index !== indexNumber
                                );
                                handleOnApplyFilter("remove", {
                                  ...commonFilter,
                                  activities: activities,
                                  page: 1,
                                });
                                return {
                                  ...currentVal,
                                  activities: activities,
                                };
                              }
                            );
                          }}
                        >
                          <KTSVG
                            path="standard_icons/cross.svg"
                            svgClassName="h-2 w-2 hover:fill-indigo-500"
                          />
                        </button>
                      </div>
                    </React.Fragment>
                  );
                })}

              {/* {((layout.activitiesFilterHandle?.dateRange) ||
              (query !== undefined && debouncedSearch)) && (
                <button
                  type="button"
                  className="group border text-center bg-white rounded-sm w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition"
                  onClick={() => {
                    handleRefresh();
                  }}
                >
                  <KTSVG
                    path="other_icons/refresh.svg"
                    className="fill-violet-500 group-hover:fill-white transition"
                    svgClassName="ml-[.313rem]"
                  />
                </button>
              )} */}
              {/* {((layout.activitiesFilterHandle?.teammembers && layout.activitiesFilterHandle?.teammembers.length > 0) || (layout.activitiesFilterHandle?.activities) || (layout.activitiesFilterHandle?.activities && layout.activitiesFilterHandle?.activities.length > 0) || (layout.activitiesFilterHandle?.dateRange) || (query !== undefined && debouncedSearch)) && (
              <button
                type="button"
                className="group border text-center bg-white rounded-sm w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition"
                onClick={() => {
                  handleRefresh()
                }}
              >
                <KTSVG
                  path="other_icons/refresh.svg"
                  className="fill-violet-500 group-hover:fill-white transition"
                  svgClassName="ml-[.313rem]"
                />
              </button>
            )} */}
            </>
          )}
        </div>
        {/* <ActivityPagination paginateData={paginateData} /> */}
        <div
          className="filterBtn hiden max-md:flex hidden px-5 py-2.5 text-sm12 border-l max-sm:order-2 max-sm:max-w-[5.875rem]"
          id="filterBtn"
        >
          <span className="group flex items-center gap-2 cursor-pointer">
            Filter
            <IKTSVG
              path={filter}
              className="fill-violet-500 group-hover:fill-indigo-500"
              svgClassName="w-4 h-4"
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default ActivityFilters;
