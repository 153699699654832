import { size } from "lodash";
import moment from "moment";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import { TableVirtuoso } from "react-virtuoso";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../assets/media/icons/standard_icons/angle_right.svg";
import cross from "../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { addCommaInNumber, pluralize } from "../../../helpers/Functions";
import {
  SYMBOLS,
  TX_ACCOUNT_INVOICE_INITIAL_FILTER,
  TX_ACCOUNT_ORDER_DRAFT_INITIAL_STATE,
  TX_ACCOUNT_ORDER_INITIAL_FILTER,
  TX_ACCOUNT_PAYOUT_INTIAL_FILTER,
} from "../../../helpers/const";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import HeaderCell from "../Table/component/HeaderCell";
import TableRow from "../Table/component/TableRow";
import { TxAccountContext } from "../core/TxAccountProvider";
import { getTXAccountOrdersListings } from "../core/_request";
import DraftInvoiceFooter from "./DraftInvoiceFooter";
import DraftInvoiceItemContent from "./DraftInvoiceItemContent";
import DropdownViewPopup from "./DropdownViewPopup";
import ExcludeContainer from "./ExcludeContainer";
import NoDataAvailable from "./NoDataAvailable";
import PopupDetailBox from "./PopupDetailBox";
import TableShimmer from "./TableShimmer";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  md:min-w-[87.5rem]"
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 "
    style={{
      ...style,
      // position: "static",
    }}
  />
);

const DraftInvoice = () => {
  const layout = useContext(TxAccountContext);
  const commonConfirmation = layout?.commonConfirmation;

  const { listing_data } = commonConfirmation;
  const { filterData } = commonConfirmation?.listing_data;
  const [loading, setIsLoading] = useState<any>(false);
  const [paginateData, setPaginateData] = useState<any>({
    current_page: layout.ordersOverViewDetails?.meta?.current_page,
    last_page: layout.ordersOverViewDetails?.meta?.last_page,
  });

  let isDisabled =
    commonConfirmation?.generate_draft_invoice_next_step &&
    !commonConfirmation?.finish;

  const [height, setHeight] = useState<any | undefined>(undefined);
  let timeout: any;
  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector(
        "#sales-repalcement-lazyload0"
      );
      const thead = document.querySelector("#tableId11 table thead");
      const theadHeight: any = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;
      // setTimeout(() => {
      //   nomoreListingHeight = nomoreListing?.clientHeight;
      // }, 2000);
      const dataHeight = theadHeight + draftInvoiceList?.length * 45;
      if (divElement) {
        if (divElement) {
          let elemHeight = dataHeight + theadHeight + 1;

          setHeight(elemHeight);
        } else {
          setTimeout(renderCall, 100);
        }
      }
    }, 500);
  };
  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    // Clear the timeout in case the component unmounts or the effect re-runs
    return () => {
      clearTimeout(timeout);
    };
  });

  const mainScrollingRef: any = useRef<any>();
  const scroll = (scrollOffset: any) => {
    let replaceTable: any = document.getElementById(
      "sales-repalcement-lazyload"
    );
    replaceTable && (replaceTable.scrollLeft += scrollOffset);
  };

  const [draftInvoiceList, setDraftInvoiceList] = useState<any>(
    listing_data?.list
  );
  const [excludeOrderValue, setExcludeOrderValue] = useState<any>({
    excludeOrder: "",
    excludeOrderFilterChip: [],
    invoice_number: "",
    prefix_invoice_number: "",
    reference: "",
    invoiceDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    dueDate: new Date(new Date().setDate(new Date().getDate() + 29)),
  });

  useEffect(() => {
    setExcludeOrderValue((current: any) => {
      return {
        ...current,
        invoice_number: filterData?.invoiceNumberDetails?.invoiceNumber,
        prefix_invoice_number: filterData?.invoiceNumberDetails?.prefix,
      };
    });
  }, [filterData?.invoiceNumberDetails]);

  const newData = layout.orderFilters;

  const fetchMoreData = async (data: any) => {
    const { page, ...rest } = newData;
    const orderFilters = {
      page: data?.page,
      ...rest,
    };

    try {
      const response = await getTXAccountOrdersListings({
        orderFilters,
        exclude_order_ids:
          layout.commonConfirmation?.listing_data?.filterData?.excludesOrderId,
      });
      if (response) {
        setIsLoading(false);
        setPaginateData((pre: any) => {
          return {
            ...pre,
            current_page: response?.meta?.current_page,
            last_page: response?.meta?.last_page,
          };
        });
        setDraftInvoiceList((pre: any) => {
          return [...pre, ...response?.data?.orders];
        });
      }
    } catch (error) {
      // Handle error here if needed
      console.error("Error fetching more data:", error);
      throw error; // Rethrow the error to be caught by the caller
    }
  };

  // GET TXACCOUNT FOR ORDERS LISTING
  const { refetch, isLoading: orderLoading } = useQuery(
    [`TX-ACCOUNT-ORDERS-LISTING`, layout.orderFilters],
    () =>
      getTXAccountOrdersListings({
        orderFilters: layout.orderFilters,
        exclude_order_ids:
          layout.commonConfirmation?.listing_data?.filterData?.excludesOrderId,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      enabled:
        layout.orderFilters?.order_by && layout.orderFilters?.sort_order
          ? true
          : false,
      onSettled: (data, err) => {
        if (data?.data?.orders && size(data?.data?.orders) > 0) {
          setDraftInvoiceList(data?.data?.orders);
        }
      },
    }
  );

  useEffect(() => {
    const thead = document.querySelector("#tableId11 table thead");
    const theadHeight: any = thead?.scrollHeight;
    const dataLength = size(draftInvoiceList);

    const dataHeight = theadHeight + dataLength * 45;
    setHeight(dataLength > 10 ? 400 : dataHeight);
  });

  return (
    <>
      <div className="main-content-wrap bg-white">
        <div className="inner_wrap flex flex-col lg2:h-[calc(100vh-3.8125rem)]">
          <div className="offcanvas-header flex justify-between border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 pr-5 pl-[1.875rem]"
              id="offcanvasRightLabel"
            >
              Draft invoice
              {commonConfirmation?.draftData?.invoice_number &&
                `: ${commonConfirmation?.draftData?.invoice_number}`}
            </h5>

            {/* CLOSE DRAFT */}
            <button
              type="button"
              className="flex items-center justify-center w-9 border-l box-content group"
              data-tooltip-id={`close-draft`}
              onClick={() => {
                layout?.setCommonConfirmation(
                  TX_ACCOUNT_ORDER_DRAFT_INITIAL_STATE
                );
                if (
                  commonConfirmation?.generate_draft_invoice_next_step &&
                  commonConfirmation?.generate_draft_invoice_next_step_disabled
                ) {
                  layout.setCommonFilter((pre: any) => {
                    return {
                      orderFilters: TX_ACCOUNT_ORDER_INITIAL_FILTER,
                      orderPayoutSeller: TX_ACCOUNT_PAYOUT_INTIAL_FILTER,
                      invoicesFilter: TX_ACCOUNT_INVOICE_INITIAL_FILTER,
                    };
                  });
                }
                layout.setOrderFilters((current: any) => {
                  if (
                    commonConfirmation?.generate_draft_invoice_next_step &&
                    commonConfirmation?.generate_draft_invoice_next_step_disabled
                  )
                    return TX_ACCOUNT_ORDER_INITIAL_FILTER;
                  else
                    return {
                      ...current,
                      order_by: "",
                      sort_order: "asc",
                    };
                });
              }}
            >
              <TooltipPortal
                id={`close-draft`}
                content={"Close"}
                place="left"
                className="text-center  !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-100 max-w-[9rem] cursor-default "
              />
              <IKTSVG
                path={cross}
                className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                svgClassName="w-2.5 h-2.5"
              />
            </button>
          </div>
          <div className="offcanvas-body max-h-full w-full flex flex-col overflow-hidden h-full">
            <div className="inner_body flex flex-wrap h-full">
              {draftInvoiceList?.length === 0 ? (
                <NoDataAvailable />
              ) : (
                <>
                  {/* LEFT SIDE TABLE */}
                  <div className="left_body border-r lg2:w-1/2 w-full h-full scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full overflow-auto p-5 flex flex-col">
                    <div className="totle_order mb-5">
                      <h5 className="font-semibold text-sm14 leading-4">
                        {`${
                          filterData?.orders?.value
                            ? addCommaInNumber(filterData?.orders?.value)
                            : "0"
                        }`}{" "}
                        {pluralize(filterData?.orders?.value || 0, "order")}
                      </h5>
                    </div>
                    {filterData?.orders?.value > 0 && (
                      <div className="left_table border h-full rounded overflow-hidden">
                        {orderLoading ? (
                          <TableShimmer loader={orderLoading} />
                        ) : (
                          <div className="accordion-body relative h-full">
                            <div
                              className={`order-replacement-table  overflow-auto scrollbar-thin scrollbar-thumb-violet-800
                         scrollbar-thumb-rounded-full hover-style2 lg2:!h-full invoice-table
                         `}
                              id={`tableId11`}
                              style={{
                                height: `${height}px`,
                              }}
                            >
                              <TableVirtuoso
                                data={draftInvoiceList}
                                overscan={200}
                                totalCount={draftInvoiceList?.length}
                                id={"sales-repalcement-lazyload"}
                                endReached={() => {
                                  if (
                                    paginateData?.last_page >
                                      paginateData?.current_page &&
                                    !loading
                                  ) {
                                    setIsLoading(true);
                                    let nextPage: number =
                                      paginateData?.current_page + 1;

                                    if (nextPage) {
                                      fetchMoreData({
                                        page: nextPage,
                                        per_page: 20,
                                      });
                                    }
                                  }
                                }}
                                components={{
                                  Table: Table,
                                  TableHead: TableHead,
                                  TableRow: (props) => {
                                    return (
                                      <TableRow
                                        {...props}
                                        excludeOrders={
                                          excludeOrderValue?.excludeOrderFilterChip
                                        }

                                        // hoveredId={hoveredId}
                                        // setHoveredId={setHoveredId}
                                        // showOrder={showOrder}
                                      />
                                    );
                                  },
                                  TableFoot: (props) => {
                                    if (
                                      draftInvoiceList?.length ===
                                        paginateData?.total &&
                                      paginateData?.current_page > 1
                                    ) {
                                      return (
                                        <tfoot
                                          className={`text-center text-sm13 py-3 px-2.5 bg-white border-t`}
                                          id={`NoMoreListing`}
                                        >
                                          <tr className="border-b bg-white">
                                            <td
                                              colSpan={11}
                                              align="center"
                                              className="h-[2.8125rem]"
                                            >
                                              No more items to show
                                            </td>
                                          </tr>
                                        </tfoot>
                                      );
                                    } else {
                                      return null;
                                    }
                                  },
                                }}
                                fixedHeaderContent={() => (
                                  <tr className="bg-white">
                                    <HeaderCell
                                      title="Team"
                                      width="min-w-[7.5rem]"
                                      arrow={true}
                                      postKey="name"
                                      padding="pl-4"
                                    />
                                    <HeaderCell
                                      title="Team member"
                                      width="min-w-[7.5rem]"
                                      arrow={true}
                                      postKey="username"
                                    />
                                    {/* <HeaderCell
                                 title="Team member"
                                 width="min-w-[7.5rem]"
                                 arrow={false}
                                 postKey="team_member"
                               /> */}

                                    <HeaderCell
                                      title="Marketplace"
                                      width="min-w-[7.5rem]"
                                      arrow={true}
                                      postKey="exchange_title"
                                    />

                                    <HeaderCell
                                      title="Order ID"
                                      width="min-w-[7.5rem]"
                                      arrow={false}
                                      postKey="order_id"
                                    />
                                    <HeaderCell
                                      title="Marketplace ID"
                                      width="min-w-[7.5rem]"
                                      arrow={false}
                                      postKey="order_id"
                                    />

                                    <HeaderCell
                                      title={"Order date"}
                                      width="min-w-[7.5rem]"
                                      arrow={true}
                                      postKey="order_date_time"
                                    />

                                    <HeaderCell
                                      title="Proceed price"
                                      width="min-w-[7.5rem]"
                                      arrow={true}
                                      postKey="transaction_value"
                                    />

                                    <HeaderCell
                                      // title="Order value"
                                      title="Marketplace …	"
                                      width="min-w-[7.5rem]"
                                      arrow={false}
                                      postKey="transaction_value"
                                    />

                                    <HeaderCell
                                      title="Seller payout"
                                      width="min-w-[7.5rem]"
                                      arrow={false}
                                      postKey="transaction_value"
                                    />

                                    <HeaderCell
                                      title="Tixstock rev …"
                                      width="min-w-[7.5rem]"
                                      arrow={false}
                                      postKey="tixstock_revenue"
                                    />
                                    <HeaderCell
                                      title="Penalty amount"
                                      width="min-w-[7.5rem]"
                                      arrow={false}
                                      postKey="tixstock_revenue"
                                    />

                                    <HeaderCell
                                      title="Event"
                                      width="min-w-[14.375rem]"
                                      arrow={true}
                                      postKey="event_description"
                                    />

                                    <HeaderCell
                                      title="Venue"
                                      width="min-w-[10.625rem]"
                                      arrow={true}
                                      postKey="event_venue"
                                    />

                                    <HeaderCell
                                      title="Event date"
                                      width="min-w-[7.5rem]"
                                      arrow={true}
                                      postKey="event_date"
                                    />

                                    <HeaderCell
                                      title="Ticket details"
                                      width="min-w-[10.625rem]"
                                      arrow={true}
                                      postKey="ticket_section"
                                    />

                                    <HeaderCell
                                      title="Qty"
                                      width="min-w-[3.4375rem]"
                                      arrow={true}
                                      postKey="ticket_quantity"
                                    />

                                    <HeaderCell
                                      title="Ticket type"
                                      width="min-w-[7.5rem]"
                                      arrow={true}
                                      postKey="ticket_type"
                                    />

                                    <HeaderCell
                                      title="Order status	"
                                      width="min-w-[7.5rem]"
                                      arrow={true}
                                      postKey="order_status"
                                    />

                                    <HeaderCell
                                      title="Internal orde …"
                                      width="min-w-[7.5rem]"
                                      arrow={false}
                                      postKey="paid"
                                    />

                                    <HeaderCell
                                      title="Payment status"
                                      width="min-w-[7.5rem]"
                                      arrow={true}
                                      postKey="paid"
                                    />
                                    <HeaderCell
                                      title="Marketplace pay …"
                                      width="min-w-[7.5rem]"
                                      arrow={false}
                                      postKey="paid"
                                    />

                                    <th className="sticky right-0 ml-auto  p-0 w-[5.0625rem]">
                                      <div
                                        className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white"
                                        id="left-shad-list"
                                      >
                                        <div className="arrow_buttons flex rounded ">
                                          <button
                                            className="group rounded-l transition px-[.5625rem] py-2"
                                            type="button"
                                            onClick={(e: any) => scroll(-120)}
                                          >
                                            <IKTSVG
                                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                                              path={angleLeftSVG}
                                              svgClassName="w-1.5 h-[.5625rem]"
                                            />
                                          </button>
                                          <button
                                            className="group rounded-r transition px-[.5625rem] py-2"
                                            type="button"
                                            onClick={(e: any) => scroll(120)}
                                          >
                                            <IKTSVG
                                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                                              path={angleRightSVG}
                                              svgClassName="w-1.5 h-[.5625rem]"
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                )}
                                fixedFooterContent={() => {
                                  return (
                                    <tr className="border-b bg-white">
                                      <td colSpan={0}>Hi</td>
                                    </tr>
                                  );
                                }}
                                itemContent={(sIndex: any, list: any) => {
                                  return (
                                    <DraftInvoiceItemContent
                                      sIndex={sIndex}
                                      list={list}
                                    />
                                  );
                                }}
                              />
                            </div>
                            {loading && (
                              <div
                                id={`child2`}
                                className={`order-[10000000] m-1 z-99999999 draft_invoice_lazyload
                   `}
                              >
                                <div className="bg-gray-100 rounded mr-1 mb-2 w-full shimmer-effect append-child h-[1.875rem] !overflow-hidden"></div>
                                <div className="bg-gray-100 rounded mr-1 mt-1 mb-2 w-full shimmer-effect append-child h-[1.875rem]  !overflow-hidden"></div>
                                {/* <div className="bg-gray-100 rounded mr-1 mb-2 w-full shimmer-effect append-child h-[1.875rem]  !overflow-hidden"></div> */}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* RIGHT SIDE -  DETAILS */}
              <div
                id="right_body"
                className="right_body border-r lg2:w-1/2 w-full h-full scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full overflow-auto p-5"
              >
                <div className="totle_order mb-5">
                  <h5 className="font-semibold text-sm14 leading-4">
                    Invoice details
                  </h5>
                </div>
                <div className="right_block ">
                  {commonConfirmation?.draftData?.status && (
                    <div className=" after_border flex flex-wrap pb-5 -mx-2.5">
                      <PopupDetailBox
                        label="Invoice status"
                        headerValue={commonConfirmation?.draftData?.status}
                        isDisabled={true}
                      />
                    </div>
                  )}
                  <div
                    className={`flex flex-wrap gap-y-5 ${
                      commonConfirmation?.draftData?.status ? "pt-5" : ""
                    } -mx-2.5`}
                  >
                    {/* MARKETPLACE */}
                    {filterData?.marketplace?.id && (
                      <PopupDetailBox
                        label="Marketplace"
                        headerValue={filterData?.marketplace?.name}
                        isDisabled={isDisabled}
                      />
                    )}

                    {/* TEAM DROPDOWN */}
                    <DropdownViewPopup
                      isDisabled={isDisabled}
                      options={
                        commonConfirmation?.listing_data?.filterData
                          ?.teamsOption?.options
                      }
                      value={
                        commonConfirmation?.listing_data?.filterData
                          ?.teamsOption?.value
                      }
                      excludeValue={
                        commonConfirmation?.listing_data?.filterData
                          ?.teamsOption?.excludeValue
                      }
                    />

                    {/* EVENT DATE */}
                    {filterData?.event_date?.endDate && (
                      <PopupDetailBox
                        label="Event date"
                        headerValue={`${moment(
                          filterData?.event_date?.startDate
                        ).format("DD/MM/YYYY")} - ${moment(
                          filterData?.event_date?.endDate
                        ).format("DD/MM/YYYY")}`}
                        isDisabled={isDisabled}
                      />
                    )}

                    {/* ORDER STATUS */}
                    {size(filterData?.order_status) > 0 && (
                      <PopupDetailBox
                        label="Order status"
                        headerValue={filterData?.order_status}
                        isDisabled={isDisabled}
                      />
                    )}

                    {/* ORDERS */}
                    <PopupDetailBox
                      label={pluralize(filterData?.orders?.value || 0, "Order")}
                      headerValue={filterData?.orders?.value}
                      countValue={
                        filterData?.orders?.excludeOrders
                          ? `-${filterData?.orders?.excludeOrders}`
                          : ""
                      }
                      isDisabled={isDisabled}
                    />
                    {/* INVOICE VALUE */}
                    <PopupDetailBox
                      label="Invoice value"
                      headerValue={
                        filterData?.invoiceValue?.value
                          ? `${SYMBOLS[filterData?.invoiceValue?.currency]}${
                              filterData?.invoiceValue?.value
                            }`
                          : ""
                      }
                      countValue={
                        filterData?.invoiceValue?.excludeInvoice
                          ? `${SYMBOLS[filterData?.invoiceValue?.currency]}-${
                              filterData?.invoiceValue?.excludeInvoice
                            }`
                          : ""
                      }
                      isDisabled={isDisabled}
                    />
                  </div>
                </div>

                {/* EXCLUDE ORDERS */}
                <ExcludeContainer
                  draftInvoiceList={draftInvoiceList}
                  excludeOrderValue={excludeOrderValue}
                  setExcludeOrderValue={setExcludeOrderValue}
                  setDraftInvoiceList={setDraftInvoiceList}
                  isDisabled={isDisabled}
                  refetch={refetch}
                />
              </div>
            </div>
          </div>

          {/* FOOTER */}
          <DraftInvoiceFooter
            commonConfirmation={commonConfirmation}
            setCommonConfirmation={layout?.setCommonConfirmation}
            excludeOrderValue={excludeOrderValue}
            ordersCount={filterData?.orders?.value}
          />
        </div>
      </div>
    </>
  );
};
export default DraftInvoice;
