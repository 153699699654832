/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DownAngle from "../../assets/media/icons/other_icons/down-angle.svg";
import infoIcon from "../../assets/media/icons/other_icons/info.svg";
import "../../assets/styles/datepicker.scss";
import TooltipPortal from "../../pages/tx_trade/table/component/TooltipPortal";
import { IKTSVG } from "../ui/IKTSVG";
import { KTSVG } from "../ui/KTSVG";

const CustomInput = forwardRef((props: any, ref) => {
  return <input {...props} ref={ref} />;
});

const DatePickerField = ({
  value,
  name,
  handleOnChange,
  placeholder,
  isDateRange = false,
  disabledKeyboardNavigation = true,
  height = "h-8",
  reff,
  width = "",
  format = "",
  error = "",
  IsIconRequire = true,
  id,
  isCancelRemoveDate = false,
  placeholderMoveTop = true,
  minDate = null,
  maxDate = null,
  WithIcon = false,
  withTooltip = false,
  tooltipContent = "",
  LeftPanelDate = false,
  leftPanelDateId = "",
  todayDateText = null,
  isDisabled = false,
  padding = "",
  popperClassName = "",
  iconWidth = false,
  placeholderClass = "",
  showTimeSelect = false,
}: any) => {
  // const [startDate, setStartDate] = useState<any>(value?.startDate ? value?.startDate : value);
  const [startDate, setStartDate] = useState<any>(null);
  const [daysData, setDaysData] = useState<any>({ item: { name: "Any date" } });
  const [endDate, setEndDate] = useState<any>(
    value?.endDate ? value?.endDate : "null"
  );
  const [startTempDate, setStartTempDate] = useState(value);
  const [focus, setFocus] = useState(false);
  let tempRef: any = React.useRef();
  const calRef: any = reff ? reff : tempRef;
  const inputRef: any = useRef<any>(null);
  let initialToggle = {
    initialToggle: false,
    postToggle: false,
  };
  const [toggle, setToggle] = useState<any>(initialToggle);

  useEffect(() => {
    if (isDateRange) {
      if (value?.startDate && value?.endDate) {
        setStartDate(value?.startDate ? value?.startDate : null);
        setEndDate(value?.endDate ? value?.endDate : null);
      } else {
        setStartDate(null);
        setEndDate(null);
      }
    } else {
      setStartDate(value);
    }
  }, [value]);

  const reset = () => {
    setStartDate(null);
    setEndDate(null);
    let event = { target: { name: name, value: "" } };
    handleOnChange(event);
  };

  const cancel = () => {
    calRef.current.setOpen(false);
    if (isCancelRemoveDate) {
      if (isDateRange && value?.startDate && value?.endDate) {
        setStartDate(value?.startDate);
        setEndDate(value?.endDate);
        let event = {
          target: {
            name: name,
            value: { startDate: value?.startDate, endDate: value?.endDate },
          },
        };
        handleOnChange(event);
      }
    } else {
      if (isDateRange && value?.endDate) {
        setStartDate(value?.startDate);
        setEndDate(value?.endDate);
        let event = {
          target: {
            name: name,
            value: { startDate: value?.startDate, endDate: value?.endDate },
          },
        };
        handleOnChange(event);
      }
    }
  };

  const confirm = () => {
    calRef.current.setOpen(false);
    setToggle(initialToggle);

    if (isDateRange) {
      let event = {
        target: {
          name: name,
          value: { startDate: startDate, endDate: endDate },
          filterName: { item: { name: "custom-range" } },
        },
      };
      handleOnChange(event);
    } else if (startTempDate) {
      setStartDate(startTempDate);
      let event = {
        target: {
          name: name,
          value: startTempDate,
          filterName: { item: { name: "custom-range" } },
        },
      };
      handleOnChange(event);
    }
  };

  const onClickOutside = (data: any) => {
    if (isDateRange) {
      if (value?.startDate && value?.endDate) {
        setStartDate(value?.startDate);
        setEndDate(value?.endDate);
        let event = {
          target: {
            name: name,
            value: { startDate: value?.startDate, endDate: value?.endDate },
            filterName: { item: { name: "custom-range" } },
          },
        };
        handleOnChange(event);
      } else if (!value?.endDate) {
        if (value?.startDate) {
          setStartDate(value?.startDate);
        } else {
          setStartDate(startDate);
        }
      }
    }
  };

  const onChange = (date: any) => {
    if (isDateRange) {
      const [start, end] = date;
      setStartDate(start);
      setEndDate(end);
    } else {
      if (showTimeSelect) setStartDate(date);
      setStartTempDate(date);
    }
  };

  // HANDLE DATE BY TYPING
  const handleChangeRaw = (e: any) => {
    if (!isDateRange && e?.currentTarget?.value) {
      const newRaw: any = moment(e.currentTarget.value);
      if (newRaw.isValid()) {
        setStartTempDate(
          new Date() > newRaw.toDate() ? newRaw.toDate() : startDate
        );
      }
    }
  };

  let isDatePickerOpen = document.getElementsByClassName(
    "react-datepicker__tab-loop"
  );

  const outSideClick = (e: any, date: any) => {
    if (
      !e?.target.classList?.contains("react-datepicker__input-container") &&
      !e?.target.classList?.contains("unique") &&
      !e?.target.classList?.contains("date_h")
    ) {
      setToggle({
        initialToggle: false,
        postToggle: false,
      });
      if (
        e.target.classList?.contains("close-datepicker-12212121") ||
        e.target.classList?.contains("form_multiDropDown__placeholder")
      ) {
        calRef.current.setOpen(false);
      }
    }
  };

  // DATEPICKER BOX CLAOSE IF OUTSIDE CLICKED
  useEffect(() => {
    let anyDatePanel: any = document.getElementById("left-panel-anyDate");
    document.addEventListener("mousedown", (e: any) => {
      outSideClick(e, anyDatePanel);
    });

    return () => {
      document.removeEventListener("mousedown", (e: any) => {
        outSideClick(e, anyDatePanel);
      });
    };
  }, []);

  let anyDateArray: any = [
    { name: "Any date", id: "anyDate" },
    { name: "Today", id: "today" },
    { name: "Next 7 days", id: "7Days" },
    { name: "Next 30 days", id: "30Days" },
    { name: "Next 60 days", id: "60Days" },
  ];

  const [isCustomRange, setIsCustomRange] = useState(false);

  const getNextDaysDate = (data: any) => {
    let currentDate: any = new Date();

    var nextWeekDate = new Date(
      currentDate.getTime() + data * 24 * 60 * 60 * 1000
    );

    return new Date(nextWeekDate.toISOString().slice(0, 10));
  };

  const handleAnyDate = (data: any, idx: any, item: any) => {
    let currentDate: any = new Date();
    setDaysData({ id: idx, item: item });

    if (data === "today") {
      setStartDate(currentDate);
      let event = {
        target: {
          name: name,
          value: currentDate,
          filterName: { id: idx, item: item },
        },
      };
      handleOnChange(event);
      setIsCustomRange(false);
      setToggle(initialToggle);
    } else if (data === "7Days") {
      setStartDate(currentDate);
      setEndDate(getNextDaysDate(7));
      let event = {
        target: {
          name: name,
          value: {
            startDate: currentDate,
            endDate: getNextDaysDate(7),
          },
          filterName: { id: idx, item: item },
        },
      };
      handleOnChange(event);
      setIsCustomRange(true);
      setToggle(initialToggle);
    } else if (data === "30Days") {
      setStartDate(currentDate);
      setEndDate(getNextDaysDate(30));
      let event = {
        target: {
          name: name,
          value: {
            startDate: currentDate,
            endDate: getNextDaysDate(30),
          },
          filterName: { id: idx, item: item },
        },
      };
      handleOnChange(event);
      setIsCustomRange(true);
      setToggle(initialToggle);
    } else if (data === "60Days") {
      setStartDate(currentDate);
      setEndDate(getNextDaysDate(60));
      let event = {
        target: {
          name: name,
          value: {
            startDate: currentDate,
            endDate: getNextDaysDate(60),
          },
          filterName: { id: idx, item: item },
        },
      };
      handleOnChange(event);
      setIsCustomRange(true);
      setToggle(initialToggle);
    } else {
      setStartDate(null);
      setEndDate(null);
      let event = {
        target: {
          name: name,
          value: "",
        },
      };
      handleOnChange(event);
      setIsCustomRange(false);
      setToggle(initialToggle);
    }
  };

  useEffect(() => {
    if (todayDateText) {
      if (todayDateText === "reset-date") {
        setDaysData({ item: { name: "Any date" } });
      } else {
        setDaysData({
          item: todayDateText,
        });
      }

      if (todayDateText?.name === "custom-range") {
        setIsCustomRange(true);
      }
    }
  }, [todayDateText]);

  return (
    <div
      className={`date_h relative group  ${error !== "" ? `error-field` : ``} ${
        (focus || toggle?.initialToggle) && `dp-focused`
      } ${!IsIconRequire && "hideIcon w-full"}`}
      onClick={(e: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (!toggle?.initialToggle) {
          setToggle((current: any) => {
            return {
              ...current,
              initialToggle: true,
            };
          });
        } else {
          setToggle(initialToggle);
        }
      }}
    >
      {/* CALENDER ICON */}
      <span
        className={`table-date-icon ${width} fill-violet-800 absolute left-0 top-0 flex items-center justify-center  rounded-l border-r ${
          error === "" ? `border-gray-300` : `border-rose-500`
        } ${
          !IsIconRequire ? "!hidden" : ""
        } ${iconWidth} w-[1.875rem] h-full pointer-events-none group-hover:fill-indigo-500 group-hover:border-indigo-500 group-[.dp-focused]:bg-indigo-500 group-[.dp-focused]:z-10 group-[.dp-focused]:!fill-white group-[.dp-focused]:border-indigo-500 bg-white`}
      >
        <KTSVG svgClassName="h-3 w-3" path="standard_icons/calendar.svg" />
      </span>

      {/* PLACEHOLDER */}
      {startDate && placeholder && placeholderMoveTop && (
        <div
          className={`dateplaceholder hover:text-indigo-500 ${placeholderClass}`}
        >
          {placeholder}
        </div>
      )}

      {/* INFO ICON */}
      {WithIcon && (
        <>
          <div
            className={`icon absolute left-[6.875rem] top-1/2 -translate-y-1/2 z-[1] ${
              startDate && placeholder && placeholderMoveTop && "hidden"
            }`}
            data-tooltip-id={`infoTooltipdate`}
          >
            <IKTSVG
              path={infoIcon}
              className="ml-1 fill-grey-400 block"
              svgClassName="fill-gray-400 hover:fill-indigo-500 w-3.5 h-3.5"
            />
          </div>
          <TooltipPortal
            id={`infoTooltipdate`}
            content={"The final date tickets are expected to be delivered by"}
            className="text-center !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs z-10 max-w-[9rem] cursor-default"
          />
        </>
      )}

      {withTooltip && (
        <>
          <div
            className={`icon absolute right-[.75rem] top-1/2 -translate-y-1/2 z-[1] ${
              startDate && placeholder && placeholderMoveTop && "hidden"
            }`}
            data-tooltip-id={`infoTooltipdate`}
          >
            <IKTSVG
              path={infoIcon}
              className="ml-1 fill-grey-400 block"
              svgClassName={`${
                error === "" ? "fill-gray-400" : "fill-rose-400"
              } hover:fill-indigo-500 w-3.5 h-3.5 `}
            />
          </div>
          <TooltipPortal
            id={`infoTooltipdate`}
            content={tooltipContent}
            className="text-center !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs z-10 max-w-[9rem] cursor-default"
          />
        </>
      )}
      {LeftPanelDate && (
        <>
          {toggle?.initialToggle && isDatePickerOpen?.length === 0 && (
            <div
              className={`block absolute top-full left-0 right-0 z-20 bg-white rounded shadow-lt1 mt-1`}
              id={leftPanelDateId}
            >
              <div className="date_new_dd p-2.5  flex flex-wrap -mx-[.3125rem] gap-y-2.5">
                {anyDateArray?.map((item: any, index: any) => {
                  return (
                    <div className=" w-1/2 px-[.3125rem]">
                      <span
                        className={`${
                          daysData?.item?.name?.toLowerCase()?.trim() ===
                            item?.name?.toLowerCase()?.trim() &&
                          "text-violet-800 border-indigo-500 active hover:bg-white hover:bg-opacity-[100%] hover:text-violet-800 hover:!border-indigo-500"
                        }  unique w-full border rounded px-2.5 py-[.1875rem] leading-4 block text-sm12 hover:bg-indigo-500 hover:bg-opacity-[7%] hover:border-indigo-500/20  cursor-pointer`}
                        onClick={() => {
                          handleAnyDate(item?.id, index, item);
                        }}
                        id={`date_h-${leftPanelDateId}`}
                      >
                        {item?.name}
                      </span>
                    </div>
                  );
                })}

                <div className=" w-1/2 px-[.3125rem]">
                  <span
                    className="unique unique-range w-full border rounded px-2.5 py-[.1875rem] leading-4 block text-sm12 hover:bg-indigo-500 hover:bg-opacity-[7%] hover:border-indigo-500/20  cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDaysData({ id: 7, item: { name: "custom-range" } });

                      setTimeout(() => {
                        if (calRef.current !== null && isDatePickerOpen) {
                          calRef.current.setOpen(true);
                          setToggle({
                            initialToggle: false,
                            postToggle: false,
                          });
                          setIsCustomRange(true);
                        }
                      }, 100);
                    }}
                  >
                    Custom range
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {daysData?.item?.name !== "custom-range" && leftPanelDateId ? (
        <div
          id="customDatePicker"
          className={`peer flex items-center picker-input block px-2.5 font-medium w-full ${height} text-xs bg-transparent border border-gray-300 appearance-none rounded focus:outline-none focus:ring-0 focus:border-indigo-500 group-hover:border-indigo-500 focus:bg-violet-300/50 cursor-pointer group-hover:placeholder:text-indigo-500 false placeholder:text-violet-800 pointer-events-none  ${
            toggle?.initialToggle && "border-indigo-500"
          }`}
        >
          {daysData?.item?.name ? daysData?.item?.name : placeholder}
        </div>
      ) : (
        <DatePicker
          dropdownMode="select"
          selected={startDate}
          value={startDate}
          startDate={startDate}
          endDate={showTimeSelect ? null : endDate} // This condition is for show multiple warnings while hover on Calender
          selectsRange={
            LeftPanelDate
              ? isDateRange && isCustomRange
                ? true
                : isDateRange && !isCustomRange
                ? false
                : false
              : isDateRange
          }
          id={id}
          placeholderText={placeholder}
          ref={calRef}
          onClickOutside={(data: any) => onClickOutside(data)}
          onChangeRaw={(event) => {
            id === "dateOfBirth" && handleChangeRaw(event);
          }}
          filterDate={(d) => {
            return id === "dateOfBirth" ? new Date() > d : true;
          }}
          disabledKeyboardNavigation={disabledKeyboardNavigation}
          shouldCloseOnSelect={false}
          dateFormat={
            LeftPanelDate
              ? isDateRange && isCustomRange
                ? format
                  ? format
                  : "dd MMM yyyy"
                : isDateRange && !isCustomRange
                ? "dd/MM/yyyy"
                : "dd/MM/yyyy"
              : isDateRange || showTimeSelect
              ? format
                ? format
                : "dd MMM yyyy"
              : format
              ? format
              : "dd/MM/yyyy"

            // isDateRange && isCustomRange
            //   ? format
            //     ? format
            //     : "dd MMM yyyy"
            //   : isDateRange && !isCustomRange
            //   ? "dd/MM/yyyy"
            //   : "dd/MM/yyyy"
          }
          onChange={(date: any | null) => onChange(date)}
          popperProps={{
            strategy: "fixed", // use this to make the popper position: fixed
          }}
          popperClassName={popperClassName}
          onCalendarOpen={() => setFocus(true)}
          onCalendarClose={() => setFocus(false)}
          showTimeInput={showTimeSelect}
          useWeekdaysShort={true}
          calendarStartDay={1}
          preventOpenOnFocus={true}
          className={`${padding} peer picker-input block px-2.5 font-medium w-full max-w-[6.875rem] ${height} text-xs bg-transparent border border-gray-300 appearance-none rounded focus:outline-none focus:ring-0 focus:border-indigo-500 group-hover:border-indigo-500 focus:bg-violet-300/50 cursor-pointer group-hover:placeholder:text-indigo-500 ${
            !IsIconRequire && "!max-w-full w-full"
          } ${
            LeftPanelDate && "placeholder:text-violet-800 pointer-events-none"
          }`}
          customInput={<CustomInput ref={inputRef} />}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !isDateRange) {
              confirm();
            } else {
              return true;
            }
          }}
          disabled={isDisabled}
          // minDate={minDate ? new Date() : undefined}
          minDate={minDate}
          maxDate={maxDate}
        >
          <div className="flex items-center justify-between w-full">
            <div>
              <button
                type="button"
                className="inline-flex items-center group text-violet-800 hover:text-white bg-gray-100 
                hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 fill-violet-500 hover:fill-white"
                onClick={() => reset()}
              >
                <KTSVG
                  svgClassName="h-3 w-3 min-w-[0.75] mr-2"
                  path="other_icons/refresh-right.svg"
                />
                <span>Reset</span>
              </button>
            </div>
            <div className="flex">
              <button
                onClick={(e) => cancel()}
                type="button"
                className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 mr-2"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  confirm();
                }}
                className={`${
                  isDateRange && !endDate && "pointer-events-none opacity-50"
                } text-white bg-violet-500 hover:bg-indigo-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5`}
              >
                Confirm
              </button>
            </div>
          </div>
        </DatePicker>
      )}

      {LeftPanelDate && (
        <div className="dropdownArrow absolute right-[.8125rem] top-1/2 -translate-y-1/2">
          <IKTSVG path={DownAngle} className="fill-gray-400" />
        </div>
      )}
    </div>
  );
};

export default DatePickerField;
