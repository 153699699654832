const GroupInvoiceFixedHeader = ({ style, ...props }: any) => {
  return (
    <div className="sticky top-0 w-full z-10 bg-white">
      <table id="invoice-header" className="w-full">
        <thead>
          <tr className="bg-white border-b text-gray-400 text-sm12 !p-0">
            <td className="w-[6.875rem] max-w-[6.875rem] min-w-[6.875rem] md:w-[8.125rem] md:max-w-[8.125rem] md:min-w-[8.125rem] font-medium pl-4 pr-1.5 py-2 whitespace-nowrap">
              Invoice number
            </td>
            <td className="w-[6.25rem] max-w-[6.25rem] min-w-[6.25rem] font-medium px-1.5 py-2">
              Payment date
            </td>
            <td className="w-[7.75rem] max-w-[7.75rem] min-w-[7.75rem] font-medium px-1.5 py-2">
              Status
            </td>
            <td className="w-auto font-medium px-1.5 py-2 whitespace-nowrap">
              Total
            </td>
            {/* <td className="font-medium px-1.5 py-2">Event name</td> */}
            <td className="w-[5rem] max-w-[5rem] min-w-[5rem] font-medium group ml-auto px-4 py-2.5"></td>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default GroupInvoiceFixedHeader;
