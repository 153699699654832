import clsx from "clsx";
import { LayoutContext } from "components/core/LayoutProvider";
import {
  Table,
  TableFoot,
  TableHead,
} from "components/tableComponent/virtuosoComponent/TableComponents";
import { IKTSVG } from "components/ui/IKTSVG";
import { KTSVG } from "components/ui/KTSVG";
import { addCommaInNumber, pluralize } from "helpers/Functions";
import { isArray } from "lodash";
import { TxAccountContext } from "pages/tx_account/core/TxAccountProvider";
import HeaderCell from "pages/tx_account/Table/component/HeaderCell";
import TableRow from "pages/tx_account/Table/component/TableRow";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { TableVirtuoso, Virtuoso } from "react-virtuoso";
import eyeSVG from "../../../../../assets/media/icons/other_icons/Eye.svg";
import angleLeftSVG from "../../../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../../../assets/media/icons/standard_icons/angle_right.svg";
import NoDataAvailable from "../../NoDataAvailable";
import TableShimmer from "../../TableShimmer";
import DraftInvoiceItemContent from "../DraftInvoiceItemContent";
import GroupInvoiceFixedHeader from "./GroupInvoiceFixedHeader";
import GroupInvoiceItemContent from "./GroupInvoiceItemContent";

const ViewLeftTableWrapper = ({
  data,
  loading,
  isGroupInvoice,
  state,
  meta,
  viewGroup,
  fetchMoreData,
  setIsLoading,
  setViewGroup,
}: any) => {
  const { isMobile } = useContext(LayoutContext);
  const { invoicesViewData, setInvoicesViewData } =
    useContext(TxAccountContext);

  const [height, setHeight] = useState<any | undefined>(undefined);
  const thead = document.querySelector("#tableId11 table thead");

  useEffect(() => {
    const theadHeight: any = thead?.scrollHeight;

    const dataHeight = theadHeight + data?.orders?.length * 45;
    setHeight(dataHeight);
  }, [data?.orders?.length, thead]);

  const scroll = (scrollOffset: any) => {
    let replaceTable: any = document.getElementById("invoice-table-lazyload");
    replaceTable && (replaceTable.scrollLeft += scrollOffset);
  };

  const isExpanded = useMemo(
    () =>
      isArray(invoicesViewData?.data?.orders)
        ? invoicesViewData?.data?.orders?.every(
            (item: any) => item?.isOpen === true
          )
        : false,
    [invoicesViewData?.data?.orders]
  );

  const handleCollapseAll = () => {
    setInvoicesViewData((prevState: any) => {
      return {
        ...prevState,
        data: {
          ...prevState?.data,
          orders: prevState?.data?.orders?.map((order: any) => {
            return {
              ...order,
              isOpen: !isExpanded,
            };
          }),
        },
      };
    });
  };

  return (
    <div className="flex flex-col border-r h-full p-5 w-full left_body lg2:w-1/2 overflow-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-violet-800">
      <div className="flex justify-between totle_order mb-5">
        <h5 className="text-sm14 font-semibold leading-4">
          {data?.total_orders ? addCommaInNumber(data?.total_orders) : "0"}{" "}
          {isGroupInvoice
            ? pluralize(data?.total_orders, "invoice")
            : pluralize(data?.total_orders, "order")}
        </h5>

        {/* EXPAND ALL - BUTTON */}
        {isGroupInvoice ? (
          <button
            type="button"
            className={`inline-flex group items-center hover:text-white bg-gray-100 
    hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
      (invoicesViewData?.data?.orders ?? [])?.length === 0 &&
      "pointer-events-none bg-gray-100 text-gray-400"
    }`}
            onClick={() => handleCollapseAll()}
            id="collpaseBtn"
            disabled={(invoicesViewData?.data?.orders ?? [])?.length === 0}
          >
            <KTSVG
              className={`min-w-[0.625rem] max-w-[0.63rem] mr-2 flex items-center justify-center  group-hover:fill-white 
      transition-all duration-300 ${!isExpanded && `rotate-180`} ${
                (invoicesViewData?.data?.orders ?? [])?.length === 0 &&
                "fill-gray-300"
              }`}
              path="standard_icons/Collapse.svg"
              svgClassName="w-[.5625rem] h-[.5625rem]"
            />
            {isExpanded ? "Collapse all" : "Expand all"}
          </button>
        ) : null}
      </div>
      {data?.orders?.length === 0 ? (
        <NoDataAvailable />
      ) : (
        <div className="border h-full rounded left_table overflow-hidden">
          {loading === "sorting" ? (
            <TableShimmer loader={loading ? true : false} />
          ) : (
            <div className="h-full accordion-body relative">
              <div
                className={`order-replacement-table overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full lg2:!h-full invoice-table`}
                id={`tableId11`}
                style={{
                  height: `${height}px`,
                }}
              >
                {/* NORMAL LISTING */}
                {!isGroupInvoice ? (
                  <TableVirtuoso
                    data={data?.orders}
                    height={data?.orders?.length + "px"}
                    endReached={() => {
                      if (meta?.last_page > meta?.current_page && !loading) {
                        let nextPage: number = meta?.current_page + 1;
                        setIsLoading("lazyLoad");
                        if (nextPage) {
                          fetchMoreData(
                            { page: nextPage, per_page: 20 },
                            false
                          );
                        }
                      }
                    }}
                    overscan={200}
                    totalCount={data?.orders?.length}
                    id={"invoice-table-lazyload"}
                    components={{
                      Table: (props) => (
                        <Table
                          {...props}
                          className="text-left text-xs w-full min-w-[87.5rem]"
                        />
                      ),
                      TableHead: TableHead,
                      TableRow: (props) => {
                        return (
                          <TableRow
                            {...props}
                            // showOrder={viewGroup}
                            showOrderId={"order_id"}
                            excludeOrders={state?.order_ids}
                          />
                        );
                      },
                      TableFoot: (props) => (
                        <TableFoot data={data?.orders} paginateData={meta} />
                      ),
                    }}
                    fixedHeaderContent={() => (
                      <tr className="bg-white ">
                        <HeaderCell
                          title="Team"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="name"
                          padding="pl-4"
                        />

                        <HeaderCell
                          title="Team member"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="username"
                        />

                        <HeaderCell
                          title="Marketplace"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="exchange_title"
                        />

                        <HeaderCell
                          title="Order ID"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="order_id"
                        />

                        <HeaderCell
                          title="Marketplace ID"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="order_id"
                        />

                        <HeaderCell
                          title={"Order date"}
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="order_date_time"
                        />

                        <HeaderCell
                          title="Proceed price"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="transaction_value"
                        />

                        <HeaderCell
                          // title="Order value"
                          title="Marketplace value"
                          width="min-w-[7.5rem]"
                          arrow={false}
                          postKey="transaction_value"
                        />

                        <HeaderCell
                          title="Seller payout"
                          width="min-w-[7.5rem]"
                          arrow={false}
                          postKey="tixstock_revenue"
                        />

                        <HeaderCell
                          title="Tixstock revenue"
                          width="min-w-[7.5rem]"
                          arrow={false}
                          postKey="event_description"
                        />

                        <HeaderCell
                          title="Event"
                          width="min-w-[14.375rem]"
                          arrow={true}
                          postKey="event_description"
                        />

                        <HeaderCell
                          title="Venue"
                          width="min-w-[10.625rem]"
                          arrow={true}
                          postKey="event_venue"
                        />

                        <HeaderCell
                          title="Event date"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="event_date"
                        />

                        <HeaderCell
                          title="Ticket details"
                          width="min-w-[10.625rem]"
                          arrow={true}
                          postKey="ticket_section"
                        />

                        <HeaderCell
                          title="Qty"
                          width="min-w-[3.4375rem]"
                          arrow={true}
                          postKey="ticket_quantity"
                        />

                        <HeaderCell
                          title="Ticket type"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="ticket_type"
                        />

                        <HeaderCell
                          title="Order status	"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="order_status"
                        />

                        <HeaderCell
                          title="Internal order status"
                          width="min-w-[7.5rem]"
                          arrow={false}
                          postKey="paid"
                        />

                        <HeaderCell
                          title="Payment status"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="paid"
                        />

                        <th className="p-0 w-[5.0625rem] max-md:hidden md:sticky ml-auto relative right-0">
                          <div
                            className="flex bg-white border-l h-[2.5rem] justify-end left-shad px-4 py-[.4375rem]"
                            id="left-shad-list"
                          >
                            <div className="flex rounded arrow_buttons">
                              <button
                                className="rounded-l group px-[.5625rem] py-2 transition"
                                type="button"
                                onClick={(e: any) => scroll(-120)}
                              >
                                <IKTSVG
                                  className="flex justify-center fill-violet-500 group-hover:fill-indigo-500 items-center transition"
                                  path={angleLeftSVG}
                                  svgClassName="w-1.5 h-[.5625rem]"
                                />
                              </button>
                              <button
                                className="rounded-r group px-[.5625rem] py-2 transition"
                                type="button"
                                onClick={(e: any) => scroll(120)}
                              >
                                <IKTSVG
                                  className="flex justify-center fill-violet-500 group-hover:fill-indigo-500 items-center transition"
                                  path={angleRightSVG}
                                  svgClassName="w-1.5 h-[.5625rem]"
                                />
                              </button>
                            </div>
                          </div>
                        </th>
                      </tr>
                    )}
                    itemContent={(sIndex: any, list: any) => {
                      return (
                        <>
                          <DraftInvoiceItemContent
                            sIndex={sIndex}
                            list={list}
                          />
                          {isGroupInvoice ? (
                            <td
                              className={`relative md:sticky right-0 ml-auto p-0 icon_td  border-b min-w-[2.5rem] md:min-w-[5rem] w-[5rem]`}
                            >
                              <div
                                className={clsx("border-l", {
                                  "bg-indigo-500 bg-opacity-[7%]":
                                    viewGroup?.data?.order_id ===
                                    list?.order_id,
                                })}
                              >
                                <button
                                  className={clsx(
                                    "max-md:hidden group flex items-center justify-center p-1 md:p-1 w-full "
                                  )}
                                  type="button"
                                  id={`record-view-tooltip${sIndex}`}
                                  //   onClick={() => {
                                  //     setViewGroup((current: any) => {
                                  //       return {
                                  //         ...current,
                                  //         data: {
                                  //           order_id: list?.order_id,
                                  //           invoice_details:
                                  //             list?.invoice_details,
                                  //         },
                                  //       };
                                  //     });
                                  //   }}
                                  data-tooltip-id={`eye-invoice-${sIndex}`}
                                >
                                  <TooltipPortal
                                    id={`eye-invoice-${sIndex}`}
                                    content={"View"}
                                    className="shadow text-center text-xs !bg-white !opacity-100 !px-1.5 !py-0.5 cursor-default font-medium max-w-[9rem] mt-2 z-[45]"
                                  />
                                  <IKTSVG
                                    className={`flex items-center justify-center transition group-hover:fill-indigo-500 fill-violet-500`}
                                    path={eyeSVG}
                                    svgClassName="w-4 h-[.6875rem]"
                                  />
                                </button>
                              </div>
                            </td>
                          ) : null}
                        </>
                      );
                    }}
                  />
                ) : (
                  <div
                    className="relative h-full acc_title inventory-table overflow-auto payout_pop scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-violet-800"
                    id={`payout_pop`}
                  >
                    <GroupInvoiceFixedHeader />

                    <Virtuoso
                      data={data?.orders}
                      overscan={200}
                      totalCount={data?.orders?.length}
                      id={"payout-inner-lazyload"}
                      endReached={() => {
                        if (meta?.last_page > meta?.current_page && !loading) {
                          let nextPage: number = meta?.current_page + 1;
                          setIsLoading("lazyLoad");
                          if (nextPage) {
                            fetchMoreData(
                              { page: nextPage, per_page: 20 },
                              false
                            );
                          }
                        }
                      }}
                      className={`overflow-y-auto !h-[calc(100%-2.1875rem)]`}
                      itemContent={(index, data) => {
                        return (
                          <React.Fragment key={index}>
                            <GroupInvoiceItemContent
                              item={data}
                              index={index}
                              viewGroup={viewGroup}
                              setViewGroup={setViewGroup}
                            />
                          </React.Fragment>
                        );
                      }}
                    />
                  </div>
                )}
              </div>
              {loading === "lazyLoad" && (
                <div
                  id={`child2`}
                  className={`order-[10000000] m-1 z-99999999 draft_invoice_lazyload`}
                >
                  <div className="bg-gray-100 h-[1.875rem] rounded w-full !overflow-hidden append-child mb-2 mr-1 shimmer-effect"></div>
                  <div className="bg-gray-100 h-[1.875rem] rounded w-full !overflow-hidden append-child mb-2 mr-1 mt-1 shimmer-effect"></div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewLeftTableWrapper;
