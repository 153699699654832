import React from "react";

const TableRow = React.memo(
  ({
    showOrder,
    hoveredId,
    setHoveredId,
    isGodAdmin,
    tabValue,
    excludeOrders,
    showOrderId,
    ...props
  }: any) => {
    const list: any = props?.item;

    const matchedView =
      showOrder?.show &&
      showOrder?.data &&
      showOrder?.data?.[showOrderId] === list?.[showOrderId];

    let isExcludeOrderId = excludeOrders?.includes(list.order_id);
    if (!isExcludeOrderId) {
      isExcludeOrderId = excludeOrders?.includes(list.external_order_id);
    }

    return (
      <>
        {false ? (
          <tr {...props} className="highlight shimmer-effect" />
        ) : (
          <tr
            {...props}
            key={list?.id}
            className={`border-b bg-white  
            ${isExcludeOrderId ? "!bg-rose-550" : ""}
            
            ${list?.selected && !matchedView ? "matchView" : ""} ${
              matchedView ? "matchView" : ""
            } ${list?.is_legacy_order == "true" && "legacy_block"}
            ${
              isGodAdmin &&
              list?.isLock === true &&
              tabValue === "to_be_fulfilled" &&
              "pointer-events-none !bg-gray-100"
            }`}

            // // id={`sales-tr-${sIndex}`}
            // onMouseEnter={() => {
            //   setHoveredId(list.order_id);
            // }}
            // onMouseLeave={() => setHoveredId(null)}
          />
        )}
      </>
    );
  }
);
export default TableRow;
